@import "../../sprite/_sprite.scss";
//$assetsPath : "/components/menu";
$left-menu-list-item-border-left: 4px;
$left-menu-header-height: 75px;

.left-menu {
	font-size: 16px;
	padding: 0;
	background-color: #002f69;

	z-index: 2001;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;

	// Убираю пунктирную рамку в ссылках
	a {
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}

	// делаю меню прокручиваемым (если пункты не умещаются на экран)
	overflow: hidden;

	$left-menu-scrollbar-width: 17px;
	&__block {
		min-height: 100%;
		height: 100%;
		max-width: 400px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// делаю меню прокручиваемым (если пункты не умещаются на экран)
		overflow-y: scroll;
		margin-right: -3*$left-menu-scrollbar-width;
		padding-right: 2*$left-menu-scrollbar-width;
	}
	&__is-touch-device {
		margin-right: 0;
		padding-right: 0;
	}


	// Чтобы онлайн-редактор не портил эту менюшку своей шириной
	.RadDockZone {
		max-width: 38px;
		margin-top: 50px; // Чтобы панель с кнопками редактора не загораживала кнопки виджета навигации
	}

	a {
		color: #cedbf2;
		font-size: 16px;
		text-decoration: none;
		padding: 15px 12px;

		&:hover {
			color: #fff;
		}
	}
	.active {
		a {
			color: #fff;
		}
	}

	&__header {
		cursor: pointer;
	}
	&__toggle-wrapper {
		list-style: none;
		display: block;
		white-space: nowrap;
		padding: 0 21px;
		height: 75px;

		border-left: $left-menu-list-item-border-left solid #002f69;
	}
	/*&__toggle-padding {
		padding: 0 21px !important;
		height: 75px !important;
	}*/
	&__toggle-block {
		padding: 20px 3px 29px 3px;
		float: left;
		border-bottom: 1px solid #839dc1;
		width: 30px;
		height: $left-menu-header-height;
	}
	&__logo-block {
		height: $left-menu-header-height;
	}
	&__logo-link {
		display: block;
		height: $left-menu-header-height;
		padding: 0 !important;
	}

	&__border-bottom {
		border-bottom: 1px solid #5274a0;
	}

	&__list-top {
		margin-top: 70px;
		flex-grow: 1;
	}
	&__list-bottom {
		display: block;
		padding: 0 !important;
		margin-top: 40px;
		margin-bottom: 10px; // Чтобы убрать багу, когда появляется скроллбар с прокруткой в несколько пикселей
	}
	&__list {
		display: block;
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	&__list-item {
		list-style: none;
		display: block;
		white-space: nowrap;
		padding: 0 13px 0 13px - $left-menu-list-item-border-left;
		height: 60px;

		border-left: $left-menu-list-item-border-left solid #002f69;
		&:hover {
			background-color: #002553;
			border-left: $left-menu-list-item-border-left solid #002553;
		}
		&.active, &.active:hover {
			background-color: #002553;
			border-left: $left-menu-list-item-border-left solid #00b3e3;
		}

		a {
			display: block;
			&:after {
				content: ".";
				visibility: hidden;
				clear: both;
				display: block;
				height: 0;
			}
		}
		
		i {
			display: block;
			float: left;
		}
	}

	&__label {
		padding-left: 20px;	
		line-height: 30px; // чтобы текст был по центру иконки
		float: left;
	}
	a:hover &__label {
		text-decoration: underline;
	}

	&__toggle-js {
		display: none;
	}

	// Иконки в меню
	&__toggle-icon {
		display: inline-block;
		@include sprite($left-menu-expand-menu);
		&:hover {
			@include sprite($left-menu-expand-menu-hover);
		}
	}
	&_open &__toggle-icon {
		display: inline-block;
		@include sprite($left-menu-minimize-menu);
		&:hover {
			@include sprite($left-menu-minimize-menu-hover);
		}
	}

	&__logo-icon {
		display: inline-block;
		@include sprite($left-menu-menu_logo_white);
		margin-left: 8px;
	}

	&__find-apartment-icon {
		display: inline-block;
		@include sprite($left-menu-1-m-find-apartment);
	}
	&__applications-icon {
		display: inline-block;
		@include sprite($left-menu-2-m-applications);
	}
	&__applications-responsible-icon {
		display: inline-block;
		@include sprite($left-menu-2-1-m-applications-responsible);
	}
	&__info-for-sales-icon {
		display: inline-block;
		@include sprite($left-menu-3-m-info-for-sales);
	}
	&__interaction-icon {
		display: inline-block;
		@include sprite($left-menu-4-m-interaction);
	}
	&__mortgage-icon {
		display: inline-block;
		@include sprite($left-menu-5-m-mortgage);
	}
	&__about-icon {
		display: inline-block;
		@include sprite($left-menu-6-m-about);
	}
	&__news-icon {
		display: inline-block;
		@include sprite($left-menu-7-m-news);
	}
	&__contacts-icon {
		display: inline-block;
		@include sprite($left-menu-8-m-contacts);
	}
	&__expert-etalon-pro-icon {
		display: inline-block;
		@include sprite($left-menu-9-m-expert-etalon-pro);
	}
	&__m-user-icon {
		display: inline-block;
		@include sprite($left-menu-m-user);
		float: left;
	}
	&__m-settings-icon {
		display: inline-block;
		@include sprite($left-menu-m-settings);
		float: left;
	}
	@at-root {
		.active &, &__list-item:hover & {
			&__find-apartment-icon {
				@include sprite($left-menu-1-m-find-apartment-selected);
			}
			&__applications-icon {
				@include sprite($left-menu-2-m-applications-selected);
			}
			&__applications-responsible-icon {
				@include sprite($left-menu-2-1-m-applications-responsible-selected);
			}
			&__info-for-sales-icon {
				@include sprite($left-menu-3-m-info-for-sales-selected);
			}
			&__interaction-icon {
				@include sprite($left-menu-4-m-interaction-selected);
			}
			&__mortgage-icon {
				@include sprite($left-menu-5-m-mortgage-selected);
			}
			&__about-icon {
				@include sprite($left-menu-6-m-about-selected);
			}
			&__news-icon {
				@include sprite($left-menu-7-m-news-selected);
			}
			&__contacts-icon {
				@include sprite($left-menu-8-m-contacts-selected);
			}
			&__expert-etalon-pro-icon {
				@include sprite($left-menu-9-m-expert-etalon-pro-selected);
			}
			&__m-user-icon {
				@include sprite($left-menu-m-user-selected);
			}
			&__m-settings-icon {
				@include sprite($left-menu-m-settings-selected);
			}
		}
	}

	// Мобильная версия (брейкпоинты)
	@media (max-width: 991px) {
		@at-root &__hidden-sm {
			display: none;
		}
	}
}


