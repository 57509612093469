// TODO: В левом меню выбранный пункт темного цвета с левой голубой рамкой

.submenu {
	font-size: 14px;
	color: black;
	//padding-left: 30px;
	border-bottom: 1px solid #e9e9e9;
	box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);

	// Убираю пунктирную рамку в ссылках
	a {
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}


	&__header {
		font-size: 34px;
		font-weight: bold;
		line-height: 40px;
		padding-top: 30px;
		padding-left: 40px;
	}

	&__list {
		list-style: none;
		margin-bottom: 0;
		padding-left: 40px;
		display: flex;
	}

	&__list-item {
		display: block;
		padding-right: 35px;
	}
		
	&__list-link {
		color: black;
		padding-top: 15px;
		padding-bottom: 10px;
		display: block;
		text-decoration: none;
		border-bottom: 3px solid white;
		white-space: nowrap;

		&:hover {
			border-bottom: 3px solid #1b47bd;
			text-decoration: none;
			color: black;
		}
		&:focus {
			text-decoration: none;
			color: black;
		}
	}

	&__list-item--active &__list-link {
		font-weight: bold;
		border-bottom: 3px solid #1b47bd;

		// Костыль для IE. Когда при зуме браузера 150%, 250% и т. д. нижняя рамка имеет неодинаковую ширину
		// Если этот параметр создает проблемы - выпилить его
		padding-bottom: 11px;
	}


	// Мобильное меню
	$submenu_caret_size: 4px;

	&__dropdown-menu {
		position: relative;
	}
	&__dropdown-title {
		line-height: 17px;
		font-size: 14px;
		font-weight: bold;
		text-align: left;
		color: black;
	}
	&__dropdown-menu-header {
		display: flex;
		padding-bottom: 12px;
		padding-top: 20px;
		padding-left: 30px;
		cursor: pointer;
	}
	&__dropdown-list-block {
		margin-top: 1px;
		position: absolute;
		width: 100%;
		z-index: 2;
	}
	&__dropdown-list {
		overflow: hidden;
		list-style: none;
		margin-bottom: 0;
		margin-top: 0;
		padding: 0;
		background-color: white;
		width: 100%;
		border-bottom: 1px solid #e9e9e9;
		box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.1);
	}

	&__dropdown-list-item:first-child &__dropdown-list-link {
		padding-top: 15px;
	}
	&__dropdown-list-item:last-child &__dropdown-list-link {
		padding-bottom: 15px;
	}
	&__dropdown-list-link {
		font-size: 14px;
		line-height: 18px;
		text-align: left;
		color: black;
		text-decoration: none;
		padding: 9px 17px;
		display: block;
		border-left: 3px solid transparent;

		&:hover, &:focus {
			text-decoration: none;
			background-color: #f2f4fb;
			color: black;
		}
	}
	&__list-item--active &__dropdown-list-link {
		font-weight: bold;
		background-color: #f2f4fb;
		border-left: 3px solid #1b47bd;
		color: black;
	}

	&__caret {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 11px;
		margin-top: 8px;
		vertical-align: middle;
		border-right: $submenu_caret_size solid transparent;
		border-left: $submenu_caret_size solid transparent;
		border-top: 0;
		border-bottom: $submenu_caret_size dashed;
		content: "";
		color: #002f6d;
	}
	&__caret-collapse {
		border-top: $submenu_caret_size dashed;
		border-top: $submenu_caret_size solid\9;
		border-bottom: none;
	}

	&__hide {
		display: none;
	}

	// Мобильная версия (брейкпоинты)
	@media (max-width: 991px) {
		@at-root &__hidden-sm {
			display: none;
		}

		@at-root &__header {
			padding-top: 20px;
			padding-left: 20px;
		}
		@at-root &__dropdown-menu-header {
			padding-left: 20px;
		}
	}
	@media (min-width: 992px) {
		@at-root &__visible-sm {
			display: none;
		}
	}
}