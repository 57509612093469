@import "../../sprite/_sprite.scss";

$footer-padding-left: 120px;

.footer {
	padding-left: $footer-padding-left - 37px;
	padding-bottom: 30px;
	border-top: 1px solid #e8e8e8;

	// Убираю пунктирную рамку в ссылках
	a {
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}

	&__columns {
		// Т.к. баги с флексами в IE 11
		//display: flex;
		//justify-content: space-between;
		display: table;
		width: 100%;
	}
	&__column {
		//flex-grow: 1;
		display: table-cell;
		vertical-align: top;
	}

	// Ширина колонок
	&__logo-column {
		min-width: 200px;
		max-width: 200px;
		padding-left: -30px + 3px;
	}
	&__navigation-column {
		min-width: 337px;
		max-width: 420px;
	}
	&__contacts-column {
		min-width: 360px;
		max-width: 420px;
		padding-left: 90px;
	}
	&__share-column {
		min-width: 200px;
		max-width: 250px;
		padding-right: 40px;
	}


	// Заголовок, телефон, город
	&__header {
		font-size: 10px;
		font-weight: 600;
		color: black;
		opacity: 0.49;
		margin-top: 45px;
		margin-bottom: 15px;
	}
	&__phone, &__link {
		font-size: 14px;
		font-weight: 600;
		color: black;
	}
	&__link:hover, &__link:focus {
		color: black;
	}
	&__city {
		font-size: 10px;
		color: #888;
		margin-bottom: 30px;
	}

	// Навигация
	&__navigation-list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: space-between;
		height: 120px;

		a {
			color: black;
			font-size: 14px;
			font-weight: 600;
			padding: 8px 15px 8px 0;
		}
	}
	&__navigation-item {
		a {
			text-decoration: none;
		}
		//&.active a, a:hover {
		a:hover {
			text-decoration: underline;
		}
	}

	// Контакты
	&__contacts {
		display: flex;
		//flex-direction: column;
		flex-wrap: wrap;
		height: 120px;
		justify-content: space-between;
		align-content: space-between;
		//display: block;
		//clear: both;
		//content: "";
		//display: flex;
		//justify-content: space-between;
	}
	&__contacts-item {
		//float: left;
		//margin-right: 30px;
	}
	&__contacts-all {
		//margin-top: 30px;
	}

	// Социальные сети
	&__share {
		display: flex;
	}
	&__share-block {
		display: flex;
		justify-content: center;
	}
	&__share-item {
		padding-right: 5px;
	}
	&__share-label {
		font-size: 12px;
		line-height: normal;
		font-weight: 600;
		color: #828282;
		padding-left: 5px;
	}
	&__share-bottom {
		margin-top: 30px;
		justify-content: space-between;
		max-width: 370px;
	}
	&__share-bottom-item {
		display: flex;
		align-items: center;
		padding-right: 10px;
		&:last-child {
			padding-right: 0;
		}
		&:hover {
			text-decoration: none;
		}
	}


	// Иконки
	&__logo-icon {
		display: inline-block;
		@include sprite($footer-footer-logo-color);
	}
	&__facebook-icon {
		display: inline-block;
		@include sprite($footer-facebook);
	}
	&__google-plus-icon {
		display: inline-block;
		@include sprite($footer-google-plus);
	}
	&__moy-mir-icon {
		display: inline-block;
		@include sprite($footer-moy-mir);
	}
	&__odnoklassniki-icon {
		display: inline-block;
		@include sprite($footer-odnoklassniki);
	}
	&__twitter-icon {
		display: inline-block;
		@include sprite($footer-twitter);
	}
	&__vk-icon {
		display: inline-block;
		@include sprite($footer-vk);
	}
	&__telegram-icon {
		display: inline-block;
		@include sprite($footer-telegram);
	}


	// Мобильная версия
	@media (min-width: 1500px) {
		@at-root {
			&__share-column {
				max-width: 370px;
				min-width: 230px;
			}
			&__share-bottom {
				width: 224px;
			}
		}
	}
	@media (min-width: 1500px) {
		@at-root {
			&__contacts-column {
				max-width: 400px;
			}
			&__share-column {
				min-width: 280px;
			}

			&__contacts-column {
				//max-width: 370px;
				padding-left: 40px;
			}
			&__navigation-column {
				max-width: 430px;
			}
			&__share-column {
				//max-width: 200px;
			}
		}
	}
	@media (min-width: 1400px) {
		@at-root {
			&__contacts-column {
				padding-left: 40px;
			}
		}
	}
	@media (max-width: 1399px) {
		@at-root {
			&__contacts-column {
				min-width: 350px;
				max-width: 350px;
				padding-left: 20px;
			}
			&__navigation-column {
				max-width: 430px;
				min-width: 370px;
			}
			&__share-column {
				padding-right: 20px;
			}
		}
	}
	@media (max-width: 1250px) {
		@at-root {
			&__contacts-column {
				padding-left: 20px; // 20px вместо 0px т.к. контакты налезают на шкалу прокрутки в IE
			}
		}
	}
	@media (max-width: 1200px) {
		@at-root {
			& {
				padding-left: $footer-padding-left;
			}
			&__logo-column {
				display: none;
			}
		}
	}
	@media (max-width: 991px) {
		@at-root {
			&__hidden-sm {
				display: none;
			}
			&__columns {
				flex-direction: row;
				justify-content: center;
				display: flex;
			}
			&__column {
				display: block;
				flex-grow: 1;
			}
			&__logo-column {
				display: none;
			}
			&__contacts-column {
				padding-left: 0;
			}
			&__navigation-list,
			&__contacts {
				height: auto;
			}
			&__contacts {
				display: block;
			}
			&__logo-column,
			&__navigation-column,
			&__contacts-column,
			&__share-column {
				min-width: auto;
				max-width: none;
			}
			& {
				padding-left: 80px;
			}
		}
	}
	@media (max-width: 800px) {
		@at-root {
			& {
				padding-left: 40px;
			}
			&__share-block {
				justify-content: flex-start;
			}
		}
	}
	@media (max-width: 600px) {
		@at-root {
			&__columns {
				flex-direction: column;
			}
		}
	}
}