@if variable-exists("font-included-proxima-nova-regular") == false {
	@font-face {
		font-family: 'Proxima Nova Rg';
		src: url('ProximaNova-Regular.eot');
		src: //url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), // из-за этой строчки не подтягиваются шрифты в IE 9-11, она нужна для IE 8.
			url('ProximaNova-Regular.woff') format('woff'),
			url('ProximaNova-Regular.ttf') format('truetype'),
			url('ProximaNova-Regular.svg#proxima_nova_altregular') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}

$font-included-proxima-nova-regular: true;