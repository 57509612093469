.content {
	&__padding-top-md {
		padding-top: 75px;
	}
	&__padding-left-md {
		padding-left: 80px;
	}
	&__padding {	
		padding: 30px 40px;
	}

	@at-root {
		@media (max-width: 991px) {
			&__padding-top-md {
				padding-top: 0;
			}
			&__padding-left-md {
				padding-left: 0;
			}
			&__padding {	
				padding: 20px;
			}
		}
	}

	// 2 колонки (одна из них меню с якорями)
	&__columns-block {
		display: flex;
	}
	&__column-content {
		width: 74%;
		padding-right: 30px;
	}
	&__column-anchor-navigation { // колонка с якорями
		min-width: 285px;
		width: 26%;
		// располагаю по центру
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	@media (max-width: 767px) {
		@at-root &__column-anchor-navigation {
			display: none;
		}
		&__column-content {
			width: 100%;
		}
	}

	// Делаю картинки в виджет-контенте адаптивными
	.sfContentBlock, .CitiesContent {
		img {
			max-width: 100%;
		}
	}
}