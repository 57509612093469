@import "../../sprite/_sprite.scss";

.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2000;
	
	background-color: white;

	font-size: 16px;
	border-bottom: 1px solid #e8e8e8;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

	// Коректное отображение в онлайн-редакторе
	@at-root .sfPageContainer & {
		position: static;
		padding-left: 100px;
	}

	// Исправления Bootstrap
	.nav li {
		vertical-align: middle;

		a {
			padding: 0;
			&:hover, &:focus {
				background: inherit;
			}
		}
	}

	// Убираю пунктирную рамку в ссылках
	a {
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}


	// Пункты меню
	&__menu {
		display: flex;
		justify-content: space-between;
		align-content: stretch;
		padding-left: 80px;
		padding-right: 40px;
	}
	&__menu-item {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__menu-subitem {
		width: 100%;
	}
	&__first-item {
		width: 200px;
	}
	&__middle-item {
		flex-grow: 1;
	}
	&__last-item {
		width: 600px;
		min-width: 500px;
		justify-content: flex-end;
	}


	&__submenu {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
	&__submenu-item {
		white-space: nowrap;
	}
	&__submenu-item-a {
		padding-top: 5px;
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 3px;
		display: flex;
		color: #000;
		font-weight: 600;
		&:hover, &:focus {
			text-decoration: underline;
			color: #000;
		}
	}


	// Блок поиска
	&__search-group {
		margin: 0 auto;
		width: 100%;
		max-width: 445px;
		display: flex;
	}
	&__search-input {
		border: 1px solid #e8e8e8;
		border-radius: 100px;
		padding-right: 30px;
		padding-left: 20px;
		width: 100%;
		max-width: 445px;
		height: 36px;
		&::placeholder {
			color: rgba(0, 0, 0, 0.3);
		};

		line-height: inherit;
		font-size: inherit;
		font-family: inherit;
		box-sizing: border-box;
	}
	&__search-button {
		margin-left: -38px;
		background: none;
		border: none;
		padding: 4px;
		padding-right: 12px;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
		display: block;
	}
	&__search-icon {
		margin-top: 3px;
	}


	// Меню "Заявки"
	&__applications-block {
		position: relative;
	}
	&__applications-link-block {
		position: relative;
		z-index: 3;
	}
	&__menu-app {
		position: absolute;
		top: -20px;
		left: -10px;
		display: none;
		overflow: hidden;
		padding: 10px !important; // Чтобы видно было тень во время открывание и закрывания меню
		                          // т.к. в этот момент возникает overflow: hidden
	}
	&__menu-app-top {
		position: relative;
		z-index: 2;

		width: 148px;
		height: 60px;
		background-color: white;
		border: 1px solid #f1f1f1;
		border-bottom: none;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		box-shadow:
			0 -6px 12px -6px rgba(0, 0, 0, 0.12),
			-6px 0px 12px -6px rgba(0, 0, 0, 0.12),
			6px 0px 12px -6px rgba(0, 0, 0, 0.12);
	}
	&__menu-app-bottom {
		position: relative;
		top: -1px;
		left: 0;
		z-index: 1;
		padding: 20px 30px 16px 30px;

		min-width: 290px;
		border-radius: 10px;
		border-top-left-radius: 0;
		background-color: white;
		box-shadow:	0 2px 12px 0 rgba(0, 0, 0, 0.12);
		border: solid 1px #f1f1f1;
	}
	&__menu-app-header-block {
		margin-bottom: 20px;
	}
	&__menu-app-header {
		line-height: 18px;
		font-size: 14px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: 0.3px;
		color: black;
	}
	&__menu-app-hr {
		border-bottom: 1px solid #eaeaea;
		margin-bottom: 20px;
	}
	&__menu-app-header-block-2 {
		margin-bottom: 15px;
	}
	&__menu-app-header-2 {
		line-height: 18px;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: 0.3px;
		color: #999999;
	}
	&__app-menu-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	&__app-menu-link {
		padding: 10px 0;
		display: block;
		text-decoration: none;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	&__app-menu-link:hover &__app-menu-label,
	&__app-menu-link:focus &__app-menu-label {
		text-decoration: underline;
	}
	&__app-menu-link-block {
		display: flex;
	}
	&__app-menu-label {
		line-height: 18px;
		font-size: 14px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: 0.3px;
		color: black;
		display: block;
		margin-left: 8px;
		margin-top: 2px;
	}
	// Заявки для ответственных
	&__menu-app-header-block-3 {
		margin-bottom: 6px;
	}
	&__app-menu-dots-block {
		display: flex;
		justify-content: space-between;
	}
	&__no-margin {
		margin: 0;
	}
	&__app-menu-count-label {
		line-height: 18px;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: 0.3px;
		color: #7F7F7F;
	}
	&__app-menu-dots {
		background-image: linear-gradient(to right, #d2d2d2 50%, white 0%);
		background-position: bottom;
		background-size: 4px 2px;
		background-repeat: repeat-x;
		flex-grow: 1;
		margin: 0 4px;
	}


	// Меню профиля
	&__menu-profile-block {
		display: none;
		position: absolute;
		left: 0;
		top: -9px;
		width: calc(100% + 30px);

		border-radius: 10px;
		background-color: #ffffff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
		border: solid 1px #f1f1f1;
	}
	&__menu-profile-open {
		display: block;
	}
	&__menu-profile-list-block {
		padding: 70px 30px 15px 30px;
	}
	&__menu-profile-item {
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		color: black;

		display: block;
		padding: 10px 0;
		margin: 0;
		cursor: pointer;
		&:hover {
			color: black;
			text-decoration: underline;
		}
	}
	&__menu-profile-item-exit {
		color: #d0021b;
		&:hover {
			color: #d0021b;
		}
	}

	//  Прочее
	&__label {
		display: block;
		margin-left: 10px;
		color: #000;
		font-weight: 600;
	}

	&__user-name {
		margin-top: 5px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 275px;
	}
	@media (max-width: 1600px) {
		@at-root &__user-name {
			max-width: 235px;
		}
	}
	@media (max-width: 1585px) {
		@at-root &__user-name {
			max-width: 150px;
		}
	}
	@media (max-width: 1430px) {
		@at-root &__user-name {
			max-width: 120px;
		}
	}
	@media (max-width: 1060px) {
		@at-root &__user-name {
			max-width: 85px;
		}
	}
	&__user-photo {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-size: 37px auto;
		background-position: top center;
		background-repeat: no-repeat;
		display: inline-block;
	}
	&__user-block {
		padding-left: 25px;
		position: relative;
	}
	&__user-link-block {

	}
	&__user-link {
		display: flex;
		position: relative;
		z-index: 1;
	}

	&__border-left {
		border-left: 1px solid #e9e9e9;
		height: 30px;
		position: absolute;
		//margin-top: 3px;
	}

	&__logo-block {
		height: 74px;
		display: block;
		margin-left: 3px;
	}


	// Иконки в шапке
	&__logo-icon {
		display: inline-block;
		@include sprite($header-menu-logo-color);
	}
	&__search-icon {
		display: inline-block;
		@include sprite($header-search);
	}
	&__expert-etalon-pro-icon {
		display: inline-block;
		@include sprite($header-expert-etalon-pro);
	}
	&__applications-icon {
		display: inline-block;
		@include sprite($header-applications);
	}
	@if variable-exists(header-vasiliy-petrov) {
		&__valisiy-petrov-icon {
			display: block;
			@include sprite($header-vasiliy-petrov);
			width: 30px;
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
			border-radius: 50%;
		}
	}
	&__MortgageRequest-icon {
		display: block;
		@include sprite($header-application-for-a-mortgage);
	}
	&__ChangeProfileRequest-icon {
		display: block;
		@include sprite($header-edit-profile);
	}
	&__FixationRequest-icon {
		display: block;
		@include sprite($header-fix-the-customer);
	}
	&__BrowsingRequest-icon {
		display: block;
		@include sprite($header-make-an-appointment);
	}
	&__dd_promo_requests-icon {
		display: block;
		@include sprite($header-order-materials);
	}
	&__ReservationRequest-icon {
		display: block;
		@include sprite($header-reserve-a-room);
	}

	// Мобильная версия (брейкпоинты)
	@media (max-width: 991px) {
		@at-root &__hidden-sm {
			display: none;
		}
	}
	@media (max-width: 1125px) {
		@at-root {
			&__submenu-item-a {
				padding-left: 15px;
				padding-right: 15px;
			}
			&__user-block {
				padding-left: 15px;
			}
		}
	}
}
