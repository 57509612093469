@import "../../sprite/_sprite.scss";

$header-mobile-bg-color: #002553;
$header-mobile-bg-color-hover: #00224f;
$header-mobile-bg-dark: #001736;
$header-mobile-height: 54px;

.header-mobile {
	height: $header-mobile-height;
	position: relative;
	z-index: 2;

	// Чтобы эта шапка отображалась в редакторе
	@at-root .sfPageContainer & {
		display: block !important;
		margin-top: 70px;
		margin-left: 80px;
		.navbar-collapse.collapse {
			display: block !important;
		}
	}
	@at-root .sfPageContainer & {
		& {
			height: auto;
		}
		&__position-fixed-1 {
			position: static;
		}
		&__position-fixed-2 {
			position: static;
			height: auto;
		}
	}

	// Bootstrap исправления
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-collapse.collapse.in {
		display: block !important;
	}
	.navbar-toggle {
		display: block;
		margin: 0;
		margin-left: -10px;
		border-color: transparent;
		&:hover, &:focus {
			background-color: $header-mobile-bg-color-hover !important;
			border-color: $header-mobile-bg-dark;
		}
		.icon-bar {
			background-color: white;
		}
	}
	.dropdown-menu {
		> li {
			margin-top: 1px;
			padding-left: 0px;
		}
		margin-left: 20px;
		width: calc(100% - 20px) !important;
		background-color: $header-mobile-bg-color !important;
		padding-top: 0;
		padding-bottom: 0;
		box-shadow: -6px 6px 12px rgba(0,0,0,0.275) !important;
		border: none;

		position: absolute !important;
		top: 100%;
		left: 0;
		z-index: 1000;
	}
	.navbar-collapse {
		padding: 0;
		border-top: none;
	}

	// Анимация slide-down
	.dropdown-menu {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.15s ease-out;
		display: block !important;
	}
	.open > .dropdown-menu {
		max-height: 1000px;
		transition: max-height 0.25s ease-in;
	}

	// Примораживаю шапку к верху странице (при прокрутке)
	&__position-fixed-1 {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
		z-index: 1;
	}
	&__position-fixed-2 {
		position: fixed;
		left: 0;
		top: $header-mobile-height;
		height: 100%;
		overflow: hidden;
	}
	&__list-wrapper {
		height: calc(100% - #{$header-mobile-height});
		position: relative;
		&.navbar-collapse.collapse {
			height: calc(100% - #{$header-mobile-height}) !important;
			overflow-x: hidden !important; // Скрываю скроллбар (шкалу прокрутки)
		}
		//margin-right: -17px; // Скрываю скроллбар (шкалу прокрутки)
	}
	&__is-touch-device {
		padding-right: 17px;
	}
	&__list-block {
		min-height: 100%;
		padding: 15px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__scrollable {
		min-height: 100%;
		//overflow-y: scroll;
		//padding-right: 17px; // Скрываю скроллбар (шкалу прокрутки)
	}
	&__list-bottom {
		padding-top: 40px;
	}

	// Колонки в шапке
	&__columns {
		padding: 0px 20px;
		display: flex;
		justify-content: space-between;
		background-color: $header-mobile-bg-color;
	}
	&__column {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	// Меню
	&__list {
		margin: 0;
		display: block;
		width: 100%;
		background-color: inherit;
	}
	&__list-background {
		background-color: $header-mobile-bg-color;
		border-top: none;
	}
	&__list-item {
		float: none !important;
		background-color: $header-mobile-bg-color;

		a {
			padding: 8px 25px 8px 20px !important;
			line-height: 30px !important;
			background-color: $header-mobile-bg-color !important;

			&:after {
				display: block;
				clear: both;
				content: "";
			}
		}

		&.active, &:hover, &:focus {
			background-color: $header-mobile-bg-dark;
			> a {
				background-color: $header-mobile-bg-dark !important;
			}
		}
	}
	&__icon {
		float: left;
		margin-top: 6px;
	}
	&__label {
		float: left;
		font-size: 16px;
		color: #cedbf2;
		line-height: 30px;
		padding-left: 18px;
	}
	.caret {
		margin-top: 0px;
		margin-left: 8px;
		color: white;
	}

	// Иконки в шапке
	&__logo-icon {
		display: inline-block;
		@include sprite($header-mobile-mobile-logo);
	}
	&__logo-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__user-avatar-wrapper {
		position: relative;
	}
	&__user-avatar-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 1;
	}
	&__user-avatar {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-size: 30px auto;
		background-position: top center;
		background-repeat: no-repeat;
		display: inline-block;
		box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
	}
	&__user-menu-wrapper {
		display: none;
		position: absolute;
		top: 0;
		right: -15px;
		height: 150px;
		width: 110px;
	}
	&__user-top-wrapper {
		position: absolute;
		top: 6px;
		right: 5px;
	}
	&__user-bottom-wrapper {
		position: absolute;
		top: 47px;
		right: 5px;
	}
	&__user-top-block {
		width: 50px;
		height: 42px;
		background-color: white;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border: 1px solid #f1f1f1;
		border-bottom: none;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
	}
	&__user-bottom-block {
		background-color: white;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
	}
	&__user-profile-menu {
		padding: 15px 20px 10px 20px;
	}
	&__user-profile-link {
		line-height: 27px;
		padding-bottom: 5px;
		display: block;

		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		cursor: pointer;
		color: black;
		text-decoration: none;
		&:hover, &:focus {
			color: black;
			text-decoration: underline;
		}
	}
	&__user-profile-link-logout {
		color: #d0021b;
		&:hover, &:focus {
			color: #d0021b;
			text-decoration: underline;
		}
	}
	@if variable-exists(header-vasiliy-petrov) {
		&__valisiy-petrov-icon {
			@include sprite($header-vasiliy-petrov);
			background-size: inherit;
		}
	}

	// Иконки в меню
	@at-root {
		&__list .left-menu {
			&__find-apartment-icon {
				display: inline-block;
				@include sprite($header-mobile-1-m-find-apartment);
			}
			&__applications-icon {
				display: inline-block;
				@include sprite($header-mobile-2-m-applications);
			}
			&__applications-responsible-icon {
				display: inline-block;
				@include sprite($header-mobile-2-1-m-applications-responsible);
			}
			&__info-for-sales-icon {
				display: inline-block;
				@include sprite($header-mobile-3-m-info-for-sales);
			}
			&__interaction-icon {
				display: inline-block;
				@include sprite($header-mobile-4-m-interaction);
			}
			&__mortgage-icon {
				display: inline-block;
				@include sprite($header-mobile-5-m-mortgage);
			}
			&__about-icon {
				display: inline-block;
				@include sprite($header-mobile-6-m-about);
			}
			&__news-icon {
				display: inline-block;
				@include sprite($header-mobile-7-m-news);
			}
			&__contacts-icon {
				display: inline-block;
				@include sprite($header-mobile-8-m-contacts);
			}
			&__expert-etalon-pro-icon {
				display: inline-block;
				@include sprite($header-mobile-9-m-expert-etalon-pro);
			}
			&__m-user-icon {
				display: inline-block;
				@include sprite($header-mobile-10-m-rm-head);
				float: left;
			}
			&__m-settings-icon {
				display: inline-block;
				@include sprite($header-mobile-11-m-administration);
				float: left;
			}
		}

		&__list .active, &__list-item:hover {
			.left-menu {
				&__find-apartment-icon {
					@include sprite($header-mobile-1-m-find-apartment-selected);
				}
				&__applications-icon {
					@include sprite($header-mobile-2-m-applications-selected);
				}
				&__applications-responsible-icon {
					@include sprite($header-mobile-2-1-m-applications-responsible-selected);
				}
				&__info-for-sales-icon {
					@include sprite($header-mobile-3-m-info-for-sales-selected);
				}
				&__interaction-icon {
					@include sprite($header-mobile-4-m-interaction-selected);
				}
				&__mortgage-icon {
					@include sprite($header-mobile-5-m-mortgage-selected);
				}
				&__about-icon {
					@include sprite($header-mobile-6-m-about-selected);
				}
				&__news-icon {
					@include sprite($header-mobile-7-m-news-selected);
				}
				&__contacts-icon {
					@include sprite($header-mobile-8-m-contacts-selected);
				}
				&__expert-etalon-pro-icon {
					@include sprite($header-mobile-9-m-expert-etalon-pro-selected);
				}
				&__m-user-icon {
					@include sprite($header-mobile-10-m-rm-head-selected);
				}
				&__m-settings-icon {
					@include sprite($header-mobile-11-m-administration-selected);
				}
			}
		}
	}

	// Мобильная версия (брейкпоинты)
	@media (min-width: 992px) {
		@at-root &__visible-sm {
			display: none;
		}
	}
}