@import "../../sprite/_sprite.scss";
@import "pickmeup.control.scss";
@import "loader.control.scss";

.control {
	// Флажок
	&__checkbox-block {
		display: flex;
		margin-bottom: 0;
	}
	&__checkbox-input {
		display: none;
	}
	&__checkbox-label {
		line-height: 17px;
		font-size: 14px;
		color: black;
		font-weight: normal;
		padding: 1px 0 0 10px;
		margin: 0;
		cursor: pointer;
	}
	&__checkbox-icon {
		display: inline-block;
		@include sprite($control-checkbox);
		cursor: pointer;
	}
	&__checkbox-input:checked + input[type=hidden] + &__checkbox-icon {
		@include sprite($control-checkbox-selected);
	}
	&__checkbox-input:disabled + input[type=hidden] + &__checkbox-icon {
		@include sprite($control-checkbox-disable);
	}
	&__checkbox-input:disabled:checked + input[type=hidden] + &__checkbox-icon {
		@include sprite($control-checkbox-selected-disable);
	}

	&__checkbox-block {
		&__checkbox-icon {
			display: inline-block;
			@include sprite($control-checkbox);
		}
		input[type=checkbox]:checked + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-selected);
		}
		input[type=checkbox]:disabled + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-disable);
		}
		input[type=checkbox]:disabled:checked + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-selected-disable);
		}
		input[type=checkbox] {
			display: none;
		}

		// Переключатели флажками
		input[type=radio]:checked + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-selected);
		}
		input[type=radio]:disabled + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-disable);
		}
		input[type=radio]:disabled:checked + input[type=hidden] + .control__checkbox-icon {
			@include sprite($control-checkbox-selected-disable);
		}
		input[type=radio] {
			display: none;
		}
	}

	// Переключатель
	&__radiobutton-block {
		display: flex;
		margin-bottom: 0;
	}
	&__radiobutton-input {
		display: none;
	}
	&__radiobutton-label {
		line-height: 17px;
		font-size: 14px;
		color: black;
		font-weight: normal;
		padding: 1px 0 0 10px;
		margin: 0;
		cursor: pointer;
	}
	&__radiobutton-icon {
		display: inline-block;
		@include sprite($control-radiobutton);
		cursor: pointer;
	}
	&__radiobutton-input:checked + input[type=hidden] + &__radiobutton-icon {
		@include sprite($control-radiobutton-selected);
	}
	&__radiobutton-input:disabled + input[type=hidden] + &__radiobutton-icon {
		@include sprite($control-radiobutton-disable);
	}
	&__radiobutton-input:disabled:checked + input[type=hidden] + &__radiobutton-icon {
		@include sprite($control-radiobutton-selected-disable);
	}

	&__radiobutton-block {
		&__radiobutton-icon {
			display: inline-block;
			@include sprite($control-radiobutton);
		}
		input[type=radio]:checked + input[type=hidden] + .control__radiobutton-icon {
			@include sprite($control-radiobutton-selected);
		}
		input[type=radio]:disabled + input[type=hidden] + .control__radiobutton-icon {
			@include sprite($control-radiobutton-disable);
		}
		input[type=radio]:disabled:checked + input[type=hidden] + .control__radiobutton-icon {
			@include sprite($control-radiobutton-selected-disable);
		}
		input[type=radio] {
			display: none;
		}
	}


	// Выпадающий список
	&__dropdown-block {
		overflow: hidden;
		position: relative;
		display: inline-block;
	}
	&__dropdown-input {
		//width: 160px;
		height: 38px;
		padding-right: 20px;
		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
		cursor: pointer;

		@include sprite($control-dropdown-arrow2);
		background-position: top right;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
		&::-ms-expand {
			display: none;
		}
	}
	/*&__dropdown-arrow-icon {
		@include sprite($control-dropdown-arrow);
		position: absolute;
		right: 2px;
		top: 8px;
	}
	&__dropdown-bg {
		position: absolute;
		right: 5px;
		top: 14px;
		width: 30px;
		height: 10px;
		background-color: white;
	}*/


	// Текстовое поле для кадендарика
	&__datepicker {
		@include sprite($control-date-field-bg);
		background-color: white;

		//height: 38px;
		//width: auto;
		border: solid 1px #d0d1d4;
		background-color: white;
		padding: 10px 14px;

		line-height: 17px;
		font-size: 14px;
		text-align: left;
		color: black;
	}


	// Кнопка "Отправить"
	&__submit-btn {
		border: none;
		border-radius: 5px;
		border-bottom: 2px solid #003560;
		background-color: #0059a0;
		padding: 13px 40px 12px 40px;
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		text-align: center;
		color: white;
		&:hover {
			background-color: #004780;
			border-bottom-color: #003560;
		}
		&:active {
			background-color: #002f6d;
			border-bottom-color: #002f6d;
		}
		&[disabled], &.disabled {
			&, &:hover, &:active {
				background-color: #9e9e9e;
				border-bottom-color: #5f5f5f;
			}
		}


		&:visited {
			color: white;
		}
		&:hover, &:focus {
			text-decoration: none;
		}
	}

	// Кнопка обычная
	&__default-btn {
		border: none;
		border-radius: 5px;
		border: 1px solid #cedbf2;
		border-bottom: 2px solid #bac5da;
		background-color: white;
		padding: 13px 40px 12px 40px;
		line-height: 17px;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0.3px;
		text-align: center;
		color: #002f69;
		&:hover {
			border-color: rgba(0, 47, 109, 0.25);
			border-bottom-color: #90a8ca;
		}
		&:active {
			border-color: rgba(0, 47, 109, 0.4);
			border-bottom-color: #6785b0;
		}
		&[disabled], &.disabled {
			background-color: #9e9e9e;
			border-color: #9e9e9e;
			border-bottom-color: #8e8e8e;
			color: #9e9e9e;
		}


		&:visited {
			color: white;
		}
		&:hover, &:focus {
			text-decoration: none;
		}
	}

	// Ссылка
	&__link {
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		color: #337ab7;
		text-decoration: none;
		border-bottom: 1px solid #337ab7;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		background: none;
		padding: 0;
		cursor: pointer;
		&:hover, &:focus, &:active {
			text-decoration: none;
		}
		&:hover, &:link, &:visited {
			color: #337ab7;
		}
	}


	// Текст
	&__text {
		line-height: 17px;
		font-size: 14px;
		color: black;
	}


	// Заголовок
	&__header {
		font-size: 34px;
		line-height: 40px;
		font-weight: bold;
		color: black;
	}


	&__header-block {
		padding: 30px 30px 0 40px;
	}
	// Мобильная версия (брейкпоинты)
	@media (max-width: 991px) {
		@at-root &__header-block {
			padding: 20px 20px 0 20px;
		}
	}
}