@import "../../sprite/_sprite.scss";

.login {

	min-width: 320px;

	// Контейнер формы входа
	&__container {
		padding-left: 15px;
		padding-right: 15px;
		margin-right: auto;
		margin-left: auto;
	}
	@media (min-width: 768px) {
		@at-root &__container {
			width: calc(100% - 140px);
		}
	}
	@media (min-width: 992px) {
		@at-root &__container {
			width: calc(100% - 140px);
		}
	}
	@media (min-width: 1260px) {
		@at-root &__container {
			width: 1120px;
		}
	}

	// Фон страницы
	&__login-bg {
		background-image: url('../Images/main-bg.jpg');
		min-height: 100%;
		background-repeat: no-repeat;
		overflow: hidden;
		background-position: top right;
		background-size: auto 100%;
	}
	&__signup-bg {
		background-image: url('../Images/sign_up_background.jpg');
		//background-color: #7094b9;
		min-height: 100%;
		background-repeat: no-repeat;
		overflow: hidden;
		background-position: top center;
		background-size: auto 100%;
		background-color: #7998bb;
		position: relative;
		&:after {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			background-image: url('../Images/lines_for_sign_up_background.png');
			background-position: right bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			width: 474px;
			height: 305px;
		}
	}
	@media (min-width: 1600px) {
		@at-root &__signup-bg {
			background-size: 100% auto;
		}
	}
	@media (max-width: 1600px) {
		@at-root &__signup-bg:after {
			width: calc(100% / 2 - 630px / 2);
		}
	}
	@media (max-width: 989px) {
		@at-root &__signup-bg:after {
			background-image: none;
		}
	}
	&__regdone-bg-size {
		background-size: 100% auto;
	}
	@media (max-aspect-ratio: 1600/1450) {
		@at-root &__regdone-bg-size {
			background-size: auto 100%;
		}
	}
	@media (min-aspect-ratio: 1600/1450) {
		@at-root &__regdone-bg-size {
			background-size: 100% auto;
		}
	}

	// Логотип
	&__login-logo-block {
		margin-top: 130px;
		margin-bottom: 82px;
		display: none;
	}
	&__signup-logo-block {
		margin-top: 60px;
		//margin-bottom: 30px;
		display: none;
		margin-left: auto;
		margin-right: auto;
		width: 630px;
		padding: 0 15px;
	}
	&__login-logo-icon {
		display: inline-block;
		@include sprite($login-logo-login-form);
	}
	&__signup-logo-icon {
		display: inline-block;
		@include sprite($login-logo-registration-form);
	}


	// Прочие стили
	.authIn {
		margin: 0 5px;
	}
	&__oAuthProviders-block {
		margin-top: 16px;
	}

	// Ширина всей формы
	&__block {
		display: block;
		max-width: 340px;
	}

	// Заголовок
	&__header {
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
		text-align: left;
		color: black;
	}

	// Ссылки
	&__link {
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		color: #337ab7;
		text-decoration: none;
		border-bottom: 1px solid #337ab7;
		&:hover, &:focus, &:active {
			text-decoration: none;
		}
		&:link, &:visited {
			color: #337ab7;
		}
	}

	// Горизонтальная линия
	&__hr {
		border-top: 1px solid #e9e9e9;
		height: 0px;
	}

	// Текст ошибки
	&__text-danger-block {
		padding-top: 20px;
	}

	&__text {
		line-height: 17px;
		font-size: 14px;
		text-align: left;
		color: black;
	}

	// Прочее
	&__flex {
		display: flex;
		flex-wrap: wrap;
	}
	&__justify-between {
		justify-content: space-between;
	}
	&__align-items-center {
		align-items: center;
	}
	&__font-size-0 {
		font-size: 0;
	}
	&__hide {
		display: none;
	}
	&__text-center {
		text-align: center;
	}
	&__text-justify {
		text-align: justify;
	}

	// Содержимое формы входа
	&__form-block {
		padding-top: 20px;
		padding-bottom: 12px;
	}
	&__remember-me-block {
		//padding-top: 18px;
		padding-bottom: 28px;
	}
	&__form-group {
		padding-bottom: 20px;
	}
	&__label {
		line-height: 20px;
		font-size: 16px;
		text-align: left;
		color: #7F7F7F;
		font-weight: normal;
		display: block;
		margin-bottom: 6px;

		margin-bottom: 0;
		display: inline-block;
	}
	&__label-small {
		line-height: 20px;
		font-size: 14px;
		text-align: left;
		color: #7F7F7F;
		font-weight: normal;
		display: block;
		margin-bottom: 6px;

		margin-bottom: 0;
		display: inline-block;
	}
	&__input {
		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
		height: 40px;
		width: 100%;

		line-height: 17px;
		font-size: 14px;
		color: black;
		padding: 12px 14px;
	}
	&__input-danger {
		color: #d0021b;
	}
	/*&__submit-btn {
		height: 42px;
		background-color: #0059a0;
		border: 1px solid #0059a0;
		border-radius: 5px;

		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		color: #ffffff;

		padding: 13px 40px;
		text-align: center;
		display: inline-block;
	}*/

	// Содержимое блока социальных сетей
	&__social-block {
		//padding-top: 30px;
		padding-bottom: 30px;
		min-width: 320px;
	}
	&__social-helper-block { // Блок подсказки по социальным сетям
		padding-top: 30px;
	}
	&__help-block {
		cursor: pointer;
		margin-left: 10px;
	}
	// Всплывающая подсказка
	&__tooltip-wrapper {
		position: relative;
		display: none;
	}
	&__tooltip-triangle {
		width: 15px;
		height: 15px;
		transform: rotate(45deg);
		background-color: white;
		position: absolute;
		left: 27px;
		top: -24px;
		z-index: 1;
		box-shadow: -2px 2px 9px -2px rgba(0, 0, 0, 0.17);
	}
	&__tooltip-block {
		position: absolute;
		left: 35px;
		top: -70px;
		cursor: auto;
		padding: 20px;
		width: 420px;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.17);
	}
	&__tooltip-content {
		position: relative;
	}
	&__tooltip-close-block {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	&__tooltip-header {
		line-height: 18px;
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: 0.3px;
		color: black;
		padding-bottom: 16px;
	}
	&__tooltip-text {
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.3px;
		text-align: left;
		color: black;
	}
	&__tooltip-list {
		margin: 0;
		padding: 0;
		//padding-left: 25px;
		// list-style: decimal;
		// Так как цифры (в нумерованном списке) нужно выравнивать по левому краю
		padding-left: 0px;
		list-style: none;
	}
	&__tooltip-list-item {
		padding-left: 10px;
	}

	// Содержимое блока регистрации
	&__register-block {
		padding: 30px 0;
	}
	&__register-link-block {
		padding-top: 10px;
	}

	// Содержимое блока восстановления пароля
	&__reset-password-input-block {
		padding-bottom: 30px;
	}
	&__alert-danger {
		border-radius: 2px;
		background: linear-gradient(to bottom, rgba(253,236,236,1) 0%,rgba(254,244,244,1) 100%);
		box-shadow: 1px 0 20px 0 rgba(150, 150, 150, .4);
		padding: 28px;

		line-height: 17px;
		font-size: 14px;
		text-align: left;
		color: #d0021b;
		border: 1px solid #ffe5e5;
		margin-top: 35px;
	}
	&__alert-info {
		border-radius: 2px;
		background: linear-gradient(to bottom, rgba(236,242,253,1) 0%,rgba(244,247,254,1) 100%);
		box-shadow: 1px 0 20px 0 rgba(180, 180, 180, .3);
		padding: 28px;

		line-height: 20px;
		font-size: 14px;
		text-align: left;
		color: black;
		border: 1px solid #e1e9f7;
		margin-bottom: 30px;
	}

	// Содержимое блока изменения пароля
	&__change-password-block {
		margin-bottom: 30px;
	}

	// Содержимое блока "Спасибо за регистрацию"
	&__regdone-block {
		max-width: 630px;
		margin: 25px auto;
		padding: 0 15px;
	}
	&__regdone-bg {
		background-color: white;
	}
	&__regdone-content {
		padding: 0 20px;
	}
	&__regdone-img {
		background-image: url('../Images/thanks_for_registering.png');
		background-repeat: no-repeat;
		width: 248px;
		height: 220px;
		margin: 0 auto;
	}
	&__regdone-img-group {
		padding-top: 90px;
		padding-bottom: 40px;
	}
	&__regdone-header-group {
		margin-bottom: 20px;
	}
	&__regdone-text-group {
		padding-bottom: 65px;
		margin: 0 20px;
	}
	&__regdone-text {
		line-height: 20px;
		font-size: 14px;
		color: black;
	}

	// Иконки
	&__facebook-icon {
		cursor: pointer;
		display: inline-block;
		@include sprite($login-facebook-36x36);
	}
	&__vk-icon {
		cursor: pointer;
		display: inline-block;
		@include sprite($login-vk-36x36);
	}
	&__odnoklassniki-icon {
		cursor: pointer;
		display: inline-block;
		@include sprite($login-odnoklassniki-36x36);
	}
	&__google-icon {
		cursor: pointer;
		display: inline-block;
		@include sprite($login-google-plus-36x36);
	}
	&__help-icon {
		display: inline-block;
		@include sprite($login-help-for-social);
	}
	&__tooltip-close-icon {
		display: inline-block;
		@include sprite($login-close-tooltip);
	}

	@media (max-width: 1091px) {
		@at-root &__login-bg {
			background-image: none;
		}

		@at-root &__login-logo-block {
			margin-top: 30px;
			margin-bottom: 40px;
		}

		@at-root &__help-block {
			position: relative;
		}
		@at-root &__tooltip-wrapper {
			position: absolute;
			left: -330px;
			top: 100px;
		}
		@at-root &__tooltip-triangle {
			display: none;
		}
	}

	@media (max-width: 560px) {
		@at-root &__block {
			//max-width: 100%;
		}
	}

	@media (max-width: 450px) {
		@at-root &__tooltip-block {
			width: 280px;
		}
	}
}