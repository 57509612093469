.anchor-navigation {

	position: relative;
	margin-top: 60px;

	// Убираю пунктирную рамку в ссылках
	a {
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}

	&__list {
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
		// Чтобы при наведении на самую широкую строку не менялся по ширине соседний выделенный пункт
		min-width: 230px;
	}

	&__item {
		margin-bottom: 1px;
		a {
			padding: 9px;
			padding-left: 25px;
			padding-right: 20px;
			display: block;
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			white-space: nowrap;

			max-width: 250px;
			text-overflow: ellipsis;
			overflow: hidden;
			&:hover, &:focus {
				text-decoration: none;
			}
		}

		&:hover, &.active {
			background-color: #00b3e3;
			transform: skew(-40deg);

			a {
				color: white;
				text-decoration: none;
				transform: skew(40deg);
				font-family: "Proxima Nova Th"; // Proxima Nova ExtraBold
				font-weight: normal;
			}
		}
	}
}