@import "../../sprite/_sprite.scss";

.signup {
	/*background: url('../Images/sign_up_background.png');
	padding-top: 60px;
	height: 1450px;*/

	.container-fluid {
		width: 660px;
	}

	.col-md-4, .col-md-8, .col-md12 {
		padding: 0;
	}

	.row {
		margin-left: 0;
		margin-right: 0;
	}

	&__checkbox-wrapper {
		padding-top: 7px;
		padding-bottom: 7px;
	}

	&__text {
		line-height: 17px;
		font-size: 14px;
		color: black;
		font-weight: normal;
	}

	&__flex {
		display: flex;
	}

	.pageTitle {
		font-family: "Proxima Nova Rg";
		font-size: 20px;
		font-weight: 600;
		color: #000;
	}

	/*.logo {
		background: url(../Images/logo_registration_form.png);
		height: 60px;
		width: 250px;
	}*/

	.signup-form {
		margin-top: 30px;
		padding-top: 50px;
		background: #fff;
		overflow: auto;
		padding-left: 50px;
		padding-right: 65px;

		input.form-control[type="text"] {
			height: 38px;
			background-color: #ffffff;
			border: solid 1px #d0d1d4;
			border-radius: 0;
		}

		.control-label {
			font-family: "Proxima Nova Rg";
			font-size: 14px;
			color: rgba(0, 0, 0, 0.5);
			font-weight: normal;
			text-align: left;
			padding-top: 10px;
			line-height: 17px;
		}
	}

	.panel {
		border: 0;
	}

	.formFooter {
		background-image: linear-gradient(to top, rgba(103, 143, 255, 0), rgba(23, 99, 215, 0.05));
		//overflow: auto;
		background-color: #fff;
		padding: 30px 65px 40px 50px;
		margin-bottom: 95px;

	}

	.panel-heading {
		display: inline;
		padding: 0;
	}

	.formTitle {
		margin-top: 30px;
		border-bottom: 1px solid #e8e8e8;
		font-family: "Proxima Nova Rg";
		font-weight: 600;
		color: #002f6d;
		font-size: 16px;
		padding-left: 0px;
		margin-bottom: 20px;
		line-height: 20px;
		padding-bottom: 5px;
	}

	#bithDateStr {
		width: 160px;
		height: 38px;
		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
	}

	/*
	#BirthDateStr {
		// width: 160px;
		// background: url('../Images/date_field_bg.png');
		// height: 38px;
		@include sprite($signup-date-field-bg);

		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
		color: black;
		font-size: 14px;
		line-height: 17px;
		font-family: "Proxima Nova Rg";
		letter-spacing: 0.8px;
	}
	*/

	.text-box {
		font-family: "Proxima Nova Rg";
		letter-spacing: 0.8px;
		line-height: 17px;
		color: black;
	}

	#collapseOne {
		margin-top: 15px;
	}

	/*#StartWork {
		width: 160px;
		height: 38px;
		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
	}*/

	.signup-button {
		font-weight: bold;
		letter-spacing: 0.3px;
		text-transform: uppercase;
		margin-top: 30px;
	}

	&__dropdown-dateYear {
		width: 160px;
	}

	// Блок текста соглашения
	&__help-wrapper {
		position: relative;
		padding-top: 10px;
		padding-bottom: 20px;
	}
	&__help-block {
		position: absolute;
		bottom: 60px;
		left: 126px;
		width: 100%;
		padding: 25px 40px 20px 20px;

		background-color: white;
		border: 1px solid #d0d1d4;
		border-radius: 5px;
		box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.17);
		display: none;
	}
	&__help-close {
		@include sprite($login-close-tooltip);
		display: inline-block;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
	&__label {
		flex-shrink: 30;
	}


	// Мобильная версия
	@media (min-width: 991px) {
		@at-root & {
			.col-md-8 {
				width: 72%;
			}
			.col-md-4 {
				width: 28%;
			}
		}

	}

	@media (max-width: 990px) {
		@at-root & {
			.container-fluid {
				width: 100%;
			}
			.signup-form, .formFooter {
				padding-left: 30px;
				padding-right: 30px;
			}
			&__help-block {
				bottom: 60px;
				left: -15px;
				width: calc(100% + 30px);
			}
		}
	}

	@media (max-width: 500px) {
		@at-root {
			&__checkbox-block {
				display: block;
			}
			&__help-block {
				bottom: 75px;
				left: -60px;
				width: calc(100% + 120px);
			}
		}
	}

	@media (max-width: 420px) {
		.g-recaptcha {
			transform:scale(0.77);
			transform-origin:0 0;
		}
		.signup-button {
			padding: 13px 20px;
		}
	}
	@media (max-width: 350px) {
		.g-recaptcha {
			transform:scale(0.68);
			transform-origin:0 0;
		}
	}
}

//@media screen and (max-height: 575px){ #CapthaDiv, .g-recaptcha {transform:scale(0.77);transform-origin:0 0;} }

 