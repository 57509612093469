// Шрифты для Bootstrap (импортировать можно сколько угодно, подтянутся только те, которые используются)
@import "./../../assets/lib/fonts/ProximaNova/ProximaNova-Regular/fonts-regular";
@import "./../../assets/lib/fonts/ProximaNova/ProximaNova-Bold/fonts-bold";
@import "./../../assets/lib/fonts/ProximaNova/ProximaNova-Semibold/fonts-semibold";
@import "./../../assets/lib/fonts/ProximaNova/ProximaNova-ExtraBold/fonts-extrabld";

body {
	font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
	font-size: 16px;
}
.tooltip {
	font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
}
.popover {
	font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
}

// Делаю подвал сайта внизу
html, body,
#EtalonProMainPageForm,
#EtalonProMainPageForm > .form-content,
#aspnetForm,
#aspnetForm > .form-content {
	height: 100%;
}
.content {
	min-height: 100%;
	padding-bottom: 230px;
	margin-bottom: -230px;
}
