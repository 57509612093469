@import "../../sprite/_sprite.scss";

$modal_border-radius: 2px;

.modal-backdrop.fade {
	z-index: 2040;
}
.modal {
	z-index: 2050;
}

.modal-etalon-pro {
	.modal-dialog {
		box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
	}

	.modal-content {
		border-radius: $modal_border-radius;
		box-shadow: none;
		border: none;
	}

	// Шапка модального окна
	.modal-header {
		background-color: white;
		color: white;
		border-top-left-radius: $modal_border-radius;
		border-top-right-radius: $modal_border-radius;
		border-bottom: none;
		box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
		padding: 6px 20px 4px 20px;
	}

	.modal-title {
		font-size: 16px;
		font-weight: bold;
		line-height: 2.5;
		text-align: left;
		color: black;
	}

	button.close {
		padding: 0;
		cursor: pointer;
		display: inline-block;
		@include sprite($modal-close-modal-window);
		border: 0;
	}
	.close {
		color: #002f6d;
		float: right;
		font-size: 35px;
		font-weight: 600;
		line-height: 40px;
		text-shadow: 0 1px 0 #fff;
		opacity: 1; // 0.4
		margin-top: 10px;

		&:hover {
			text-decoration: none;
			cursor: pointer;
			opacity: 1; // 0.6
			color: black;
			text-shadow: 3px 0px 5px rgba(150,150,150,.3);
		}
	}

	// Тело модального окна
	.modal-body {
		padding: 20px;
		color: black;
	}

	// Подвал модального окна
	.modal-footer {
		padding: 20px 30px;
		background: linear-gradient(to bottom, rgba(236,242,253,1) 0%,rgba(244,247,254,1) 100%);
	}

	.modal-flex {
		display: flex;
		justify-content: flex-end;
	}

	.modal-flex-wrap {
		flex-wrap: wrap;
	}

	.modal-footer-flex-left {
		flex-grow: 1;
		display: flex;
	}

	.modal-submit-button {
		color: white;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		border-radius: 3px;
		background-color: #0059a0;
		border: 1px solid #2e6da4;
		line-height: 17px;
		padding: 8px 20px;
		cursor: pointer;
		&:hover {
			background-color: #004f8e;
		}
	}

	.modal-close-button {
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		text-align: center;
		color: #002f69;
		border-radius: 3px;
		background-color: #ffffff;
		border: solid 1px #cedbf2;
		padding: 8px 20px;
		cursor: pointer;
		margin-left: 20px;
		display: inline-block;
		&:hover {
			background-color: #f5f5f5;
			text-decoration: none;
		}
	}

	.link-button-black {
		color: black;
		text-decoration: none;
		border-bottom: 1px dotted black;
		cursor: pointer;
		font-size: 16px;
		line-height: 16px;

		&:hover {
			color: #555;
			border-bottom: 1px dotted #555;
		}
	}

	.modal-link-button {
		font-size: 14px;
		color: #337ab7;
		font-weight: 600;
		letter-spacing: 0.3px;
		cursor: pointer;
		padding: 5px 0 0 0;
		border-bottom: 1px solid #337ab7;
		text-decoration: none;
		display: inline-block;
	}

	// Мобильная версия (брейкпоинты)
	@media (min-width: 768px) {
		.modal-dialog {
			margin: 80px auto;
		}
	}
	@media (max-width: 370px) {
		@at-root & {
			.modal-submit-button,
			.modal-close-button {
				display: block;
				width: 100%;
				margin: 0;
			}

			.modal-close-button {
				margin-top: 15px;
			}

			.modal-flex {
				display: block;
			}
		}
	}
}