@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("ProximaNova-Regular.eot");
  src: url("ProximaNova-Regular.woff") format("woff"), url("ProximaNova-Regular.ttf") format("truetype"), url("ProximaNova-Regular.svg#proxima_nova_altregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("ProximaNova-Bold.eot");
  src: url("ProximaNova-Bold.woff") format("woff"), url("ProximaNova-Bold.ttf") format("truetype"), url("ProximaNova-Bold.svg#proxima_nova_altregular") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("ProximaNova-Semibold.eot");
  src: url("ProximaNova-Semibold.woff") format("woff"), url("ProximaNova-Semibold.ttf") format("truetype"), url("ProximaNova-Semibold.svg#proxima_nova_altregular") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url("ProximaNova-Extrabld.eot");
  src: url("ProximaNova-Extrabld.woff") format("woff"), url("ProximaNova-Extrabld.ttf") format("truetype"), url("ProximaNova-Extrabld.svg#proxima_nova_altregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.tooltip {
  font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
}

.popover {
  font-family: "Proxima Nova Rg", Helvetica, Arial, sans-serif;
}

html, body,
#EtalonProMainPageForm,
#EtalonProMainPageForm > .form-content,
#aspnetForm,
#aspnetForm > .form-content {
  height: 100%;
}

.content {
  min-height: 100%;
  padding-bottom: 230px;
  margin-bottom: -230px;
}

.layouts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.layouts__column-1-of-3, .layouts__column-2-of-3, .layouts__column-3-of-3 {
  width: 33.33333333%;
}

.layouts__indent {
  margin-left: 12px;
}

@media (max-width: 991px) {
  .layouts {
    display: block;
  }
  .layouts__indent {
    margin-left: 0;
    margin-top: 12px;
  }
  .layouts__column-1-of-3, .layouts__column-2-of-3, .layouts__column-3-of-3 {
    width: 100% !important;
  }
}

.layouts__column-1-of-2, .layouts__column-2-of-2 {
  width: 50%;
  min-width: 40%;
}

@media (max-width: 991px) {
  .layouts__column-1-of-2, .layouts__column-2-of-2 {
    width: 100% !important;
    min-width: auto;
  }
}

div.pickmeup {
  background-color: white;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #cccccc;
  width: 278px;
  height: auto;
  padding: 0;
}

div.pickmeup.pmu-view-months {
  width: 278px;
}

div.pickmeup.pmu-view-months .pmu-instance {
  padding: 5px 10px 10px 10px;
}

div.pickmeup.pmu-view-months .pmu-instance > nav {
  margin-bottom: 5px;
}

div.pickmeup.pmu-view-months .pmu-instance nav .pmu-next {
  margin-right: 15px;
}

div.pickmeup.pmu-view-months .pmu-instance nav .pmu-prev {
  margin-left: 15px;
}

div.pickmeup.pmu-view-years {
  width: 278px;
}

div.pickmeup.pmu-view-years .pmu-instance {
  padding: 5px 10px 10px 10px;
}

div.pickmeup.pmu-view-years .pmu-instance > nav {
  margin-bottom: 9px;
}

div.pickmeup.pmu-view-years .pmu-instance nav .pmu-next {
  margin-right: 15px;
}

div.pickmeup.pmu-view-years .pmu-instance nav .pmu-prev {
  margin-left: 15px;
}

div.pickmeup .pmu-instance {
  width: auto;
  height: auto;
  padding: 5px 20px 20px 20px;
}

div.pickmeup .pmu-instance .pmu-day-of-week {
  margin: 18px 0 0 0;
  font-size: 14px;
  line-height: 17px;
  color: #BDBDBD;
}

div.pickmeup .pmu-instance .pmu-day-of-week * {
  width: 30px;
}

div.pickmeup .pmu-instance .pmu-selected {
  background-color: #337ab7;
  color: white;
  border-radius: 50%;
}

div.pickmeup .pmu-instance .pmu-month {
  line-height: 17px;
  font-size: 14px;
  color: black;
  padding-top: 19px;
}

div.pickmeup .pmu-instance .pmu-month:hover {
  color: #333333;
}

div.pickmeup .pmu-instance .pmu-days {
  font-size: 0;
  line-height: 0;
}

div.pickmeup .pmu-instance .pmu-days .pmu-button {
  line-height: 30px;
  font-size: 14px;
  color: black;
  width: 30px;
  margin: 4px 2px;
}

div.pickmeup .pmu-instance .pmu-days .pmu-button:hover {
  background-color: #337ab7;
  border-radius: 50%;
  color: white;
}

div.pickmeup .pmu-instance .pmu-days .pmu-button.pmu-disabled {
  cursor: default;
  color: #DDDDDD;
  background-color: inherit;
}

div.pickmeup .pmu-instance .pmu-days .pmu-button.pmu-disabled:hover {
  font-weight: normal;
}

div.pickmeup .pmu-instance .pmu-days .pmu-today {
  background-color: #edeef1;
  border-radius: 50%;
}

div.pickmeup .pmu-instance .pmu-days .pmu-not-in-month {
  line-height: 30px;
  font-size: 14px;
  color: #7F7F7F;
}

div.pickmeup .pmu-instance .pmu-days .pmu-selected {
  background-color: #337ab7;
  border-radius: 50%;
  color: white;
}

div.pickmeup .pmu-instance .pmu-months .pmu-button {
  line-height: 30px;
  font-size: 14px;
  color: black;
  width: 50px;
  margin: 7px 7px;
}

div.pickmeup .pmu-instance .pmu-months .pmu-button:hover {
  background-color: #337ab7;
  border-radius: 15px;
  color: white;
}

div.pickmeup .pmu-instance .pmu-months .pmu-button.pmu-disabled {
  cursor: default;
  color: #DDDDDD;
  background-color: inherit;
}

div.pickmeup .pmu-instance .pmu-months .pmu-button.pmu-disabled:hover {
  font-weight: normal;
}

div.pickmeup .pmu-instance .pmu-months .pmu-selected {
  background-color: #337ab7;
  border-radius: 15px;
  color: white;
  font-weight: 600;
}

div.pickmeup .pmu-instance .pmu-years .pmu-button {
  line-height: 30px;
  font-size: 14px;
  color: black;
  width: 50px;
  margin: 8px 7px;
}

div.pickmeup .pmu-instance .pmu-years .pmu-button:hover {
  background-color: #337ab7;
  border-radius: 15px;
  color: white;
}

div.pickmeup .pmu-instance .pmu-years .pmu-button.pmu-disabled {
  cursor: default;
  color: #DDDDDD;
  background-color: inherit;
}

div.pickmeup .pmu-instance .pmu-years .pmu-button.pmu-disabled:hover {
  font-weight: normal;
}

div.pickmeup .pmu-instance .pmu-years .pmu-selected {
  background-color: #337ab7;
  border-radius: 15px;
  color: white;
  font-weight: 600;
}

div.pickmeup .pmu-instance nav {
  justify-content: space-between;
}

div.pickmeup .pmu-instance nav .pmu-next {
  margin-top: 15px;
  margin-right: 5px;
  color: transparent;
  display: none;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -266px -447px;
  width: 16px;
  height: 16px;
}

div.pickmeup .pmu-instance nav .pmu-next:hover {
  color: transparent;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -266px -447px;
  width: 16px;
  height: 16px;
}

div.pickmeup .pmu-instance nav .pmu-next:after {
  content: "";
  display: block;
  width: 40px;
  height: 30px;
  margin-top: -39px;
  margin-left: -12px;
}

div.pickmeup .pmu-instance nav .pmu-prev {
  margin-top: 15px;
  margin-left: 5px;
  color: transparent;
  display: none;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -161px -447px;
  width: 16px;
  height: 16px;
}

div.pickmeup .pmu-instance nav .pmu-prev:hover {
  color: transparent;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -161px -447px;
  width: 16px;
  height: 16px;
}

div.pickmeup .pmu-instance nav .pmu-prev:after {
  content: "";
  display: block;
  width: 40px;
  height: 30px;
  margin-top: -39px;
  margin-left: -12px;
}

div.pickmeup .pmu-instance .pmu-not-in-month:hover,
div.pickmeup .pmu-instance .pmu-today:hover,
div.pickmeup .pmu-instance .pmu-selected:hover {
  background-color: #337ab7;
  border-radius: 50%;
  color: white;
}

div.pickmeup .pmu-instance .pmu-not-in-month.pmu-selected {
  background-color: #337ab7;
  border-radius: 50%;
  color: white;
}

@-moz-keyframes rotate {
  from {
    -webkit-transform: rotate(0d);
    -moz-transform: rotate(0d);
    -ms-transform: rotate(0d);
    -o-transform: rotate(0d);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader, .loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  font-size: 0;
  text-indent: -9999px;
  margin: 0;
  border: none;
  padding: 0;
  z-index: 2000;
}

#loader:after, .loading:after {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("/content/image/preloader.png");
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1.3s;
  -moz-animation-duration: 1.3s;
  -o-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.miniLoader {
  display: block;
  height: 60px;
  background: url("/content/image/preloader.png") no-repeat center center;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1.3s;
  -moz-animation-duration: 1.3s;
  -o-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.microLoader {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/content/image/preloader_for_button.png") no-repeat center center;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1.3s;
  -moz-animation-duration: 1.3s;
  -o-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.control {
  /*&__dropdown-arrow-icon {
		@include sprite($control-dropdown-arrow);
		position: absolute;
		right: 2px;
		top: 8px;
	}
	&__dropdown-bg {
		position: absolute;
		right: 5px;
		top: 14px;
		width: 30px;
		height: 10px;
		background-color: white;
	}*/
}

.control__checkbox-block {
  display: flex;
  margin-bottom: 0;
}

.control__checkbox-input {
  display: none;
}

.control__checkbox-label {
  line-height: 17px;
  font-size: 14px;
  color: black;
  font-weight: normal;
  padding: 1px 0 0 10px;
  margin: 0;
  cursor: pointer;
}

.control__checkbox-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -253px -424px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.control__checkbox-input:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -46px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-input:disabled + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -69px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-input:disabled:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -414px -424px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block__checkbox-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -253px -424px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=checkbox]:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -46px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=checkbox]:disabled + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -69px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=checkbox]:disabled:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -414px -424px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=checkbox] {
  display: none;
}

.control__checkbox-block input[type=radio]:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -46px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=radio]:disabled + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -69px -447px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=radio]:disabled:checked + input[type=hidden] + .control__checkbox-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -414px -424px;
  width: 18px;
  height: 18px;
}

.control__checkbox-block input[type=radio] {
  display: none;
}

.control__radiobutton-block {
  display: flex;
  margin-bottom: 0;
}

.control__radiobutton-input {
  display: none;
}

.control__radiobutton-label {
  line-height: 17px;
  font-size: 14px;
  color: black;
  font-weight: normal;
  padding: 1px 0 0 10px;
  margin: 0;
  cursor: pointer;
}

.control__radiobutton-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -423px -370px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.control__radiobutton-input:checked + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -480px -148px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-input:disabled + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -398px -370px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-input:disabled:checked + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -450px -399px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-block__radiobutton-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -423px -370px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-block input[type=radio]:checked + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -480px -148px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-block input[type=radio]:disabled + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -398px -370px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-block input[type=radio]:disabled:checked + input[type=hidden] + .control__radiobutton-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -450px -399px;
  width: 20px;
  height: 20px;
}

.control__radiobutton-block input[type=radio] {
  display: none;
}

.control__dropdown-block {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.control__dropdown-input {
  height: 38px;
  padding-right: 20px;
  background-color: #ffffff;
  border: solid 1px #d0d1d4;
  border-radius: 0;
  cursor: pointer;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px 0px;
  width: 500px;
  height: 38px;
  background-position: top right;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.control__dropdown-input::-ms-expand {
  display: none;
}

.control__datepicker {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -255px -108px;
  width: 159px;
  height: 35px;
  background-color: white;
  border: solid 1px #d0d1d4;
  background-color: white;
  padding: 10px 14px;
  line-height: 17px;
  font-size: 14px;
  text-align: left;
  color: black;
}

.control__submit-btn {
  border: none;
  border-radius: 5px;
  border-bottom: 2px solid #003560;
  background-color: #0059a0;
  padding: 13px 40px 12px 40px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  color: white;
}

.control__submit-btn:hover {
  background-color: #004780;
  border-bottom-color: #003560;
}

.control__submit-btn:active {
  background-color: #002f6d;
  border-bottom-color: #002f6d;
}

.control__submit-btn[disabled], .control__submit-btn[disabled]:hover, .control__submit-btn[disabled]:active, .control__submit-btn.disabled, .control__submit-btn.disabled:hover, .control__submit-btn.disabled:active {
  background-color: #9e9e9e;
  border-bottom-color: #5f5f5f;
}

.control__submit-btn:visited {
  color: white;
}

.control__submit-btn:hover, .control__submit-btn:focus {
  text-decoration: none;
}

.control__default-btn {
  border: none;
  border-radius: 5px;
  border: 1px solid #cedbf2;
  border-bottom: 2px solid #bac5da;
  background-color: white;
  padding: 13px 40px 12px 40px;
  line-height: 17px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: #002f69;
}

.control__default-btn:hover {
  border-color: rgba(0, 47, 109, 0.25);
  border-bottom-color: #90a8ca;
}

.control__default-btn:active {
  border-color: rgba(0, 47, 109, 0.4);
  border-bottom-color: #6785b0;
}

.control__default-btn[disabled], .control__default-btn.disabled {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  border-bottom-color: #8e8e8e;
  color: #9e9e9e;
}

.control__default-btn:visited {
  color: white;
}

.control__default-btn:hover, .control__default-btn:focus {
  text-decoration: none;
}

.control__link {
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #337ab7;
  text-decoration: none;
  border-bottom: 1px solid #337ab7;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}

.control__link:hover, .control__link:focus, .control__link:active {
  text-decoration: none;
}

.control__link:hover, .control__link:link, .control__link:visited {
  color: #337ab7;
}

.control__text {
  line-height: 17px;
  font-size: 14px;
  color: black;
}

.control__header {
  font-size: 34px;
  line-height: 40px;
  font-weight: bold;
  color: black;
}

.control__header-block {
  padding: 30px 30px 0 40px;
}

@media (max-width: 991px) {
  .control__header-block {
    padding: 20px 20px 0 20px;
  }
}

.left-menu {
  font-size: 16px;
  padding: 0;
  background-color: #002f69;
  z-index: 2001;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  /*&__toggle-padding {
		padding: 0 21px !important;
		height: 75px !important;
	}*/
}

.left-menu a::-moz-focus-inner {
  border: 0;
}

.left-menu a:hover, .left-menu a:focus {
  outline: 0;
}

.left-menu__block {
  min-height: 100%;
  height: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  margin-right: -51px;
  padding-right: 34px;
}

.left-menu__is-touch-device {
  margin-right: 0;
  padding-right: 0;
}

.left-menu .RadDockZone {
  max-width: 38px;
  margin-top: 50px;
}

.left-menu a {
  color: #cedbf2;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 12px;
}

.left-menu a:hover {
  color: #fff;
}

.left-menu .active a {
  color: #fff;
}

.left-menu__header {
  cursor: pointer;
}

.left-menu__toggle-wrapper {
  list-style: none;
  display: block;
  white-space: nowrap;
  padding: 0 21px;
  height: 75px;
  border-left: 4px solid #002f69;
}

.left-menu__toggle-block {
  padding: 20px 3px 29px 3px;
  float: left;
  border-bottom: 1px solid #839dc1;
  width: 30px;
  height: 75px;
}

.left-menu__logo-block {
  height: 75px;
}

.left-menu__logo-link {
  display: block;
  height: 75px;
  padding: 0 !important;
}

.left-menu__border-bottom {
  border-bottom: 1px solid #5274a0;
}

.left-menu__list-top {
  margin-top: 70px;
  flex-grow: 1;
}

.left-menu__list-bottom {
  display: block;
  padding: 0 !important;
  margin-top: 40px;
  margin-bottom: 10px;
}

.left-menu__list {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.left-menu__list-item {
  list-style: none;
  display: block;
  white-space: nowrap;
  padding: 0 13px 0 9px;
  height: 60px;
  border-left: 4px solid #002f69;
}

.left-menu__list-item:hover {
  background-color: #002553;
  border-left: 4px solid #002553;
}

.left-menu__list-item.active, .left-menu__list-item.active:hover {
  background-color: #002553;
  border-left: 4px solid #00b3e3;
}

.left-menu__list-item a {
  display: block;
}

.left-menu__list-item a:after {
  content: ".";
  visibility: hidden;
  clear: both;
  display: block;
  height: 0;
}

.left-menu__list-item i {
  display: block;
  float: left;
}

.left-menu__label {
  padding-left: 20px;
  line-height: 30px;
  float: left;
}

a:hover .left-menu__label {
  text-decoration: underline;
}

.left-menu__toggle-js {
  display: none;
}

.left-menu__toggle-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -29px -370px;
  width: 24px;
  height: 24px;
}

.left-menu__toggle-icon:hover {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -58px -370px;
  width: 24px;
  height: 24px;
}

.left-menu_open .left-menu__toggle-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -174px -370px;
  width: 24px;
  height: 24px;
}

.left-menu_open .left-menu__toggle-icon:hover {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -145px -370px;
  width: 24px;
  height: 24px;
}

.left-menu__logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -202px -173px;
  width: 197px;
  height: 73px;
  margin-left: 8px;
}

.left-menu__find-apartment-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -324px -329px;
  width: 30px;
  height: 30px;
}

.left-menu__applications-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -184px -329px;
  width: 30px;
  height: 30px;
}

.left-menu__applications-responsible-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -254px -329px;
  width: 30px;
  height: 30px;
}

.left-menu__info-for-sales-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -412px -291px;
  width: 30px;
  height: 30px;
}

.left-menu__interaction-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -359px -329px;
  width: 30px;
  height: 30px;
}

.left-menu__mortgage-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -272px -291px;
  width: 30px;
  height: 30px;
}

.left-menu__about-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -202px -291px;
  width: 30px;
  height: 30px;
}

.left-menu__news-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -401px -251px;
  width: 30px;
  height: 30px;
}

.left-menu__contacts-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -439px -214px;
  width: 30px;
  height: 30px;
}

.left-menu__expert-etalon-pro-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -454px -108px;
  width: 30px;
  height: 30px;
}

.left-menu__m-user-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -394px -329px;
  width: 28px;
  height: 28px;
  float: left;
}

.left-menu__m-settings-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -474px -214px;
  width: 26px;
  height: 26px;
  float: left;
}

.active .left-menu__find-apartment-icon, .left-menu__list-item:hover .left-menu__find-apartment-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -289px -329px;
  width: 30px;
  height: 30px;
}

.active .left-menu__applications-icon, .left-menu__list-item:hover .left-menu__applications-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -447px -291px;
  width: 30px;
  height: 30px;
}

.active .left-menu__applications-responsible-icon, .left-menu__list-item:hover .left-menu__applications-responsible-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -219px -329px;
  width: 30px;
  height: 30px;
}

.active .left-menu__info-for-sales-icon, .left-menu__list-item:hover .left-menu__info-for-sales-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -377px -291px;
  width: 30px;
  height: 30px;
}

.active .left-menu__interaction-icon, .left-menu__list-item:hover .left-menu__interaction-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -307px -291px;
  width: 30px;
  height: 30px;
}

.active .left-menu__mortgage-icon, .left-menu__list-item:hover .left-menu__mortgage-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -237px -291px;
  width: 30px;
  height: 30px;
}

.active .left-menu__about-icon, .left-menu__list-item:hover .left-menu__about-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -436px -251px;
  width: 30px;
  height: 30px;
}

.active .left-menu__news-icon, .left-menu__list-item:hover .left-menu__news-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -366px -251px;
  width: 30px;
  height: 30px;
}

.active .left-menu__contacts-icon, .left-menu__list-item:hover .left-menu__contacts-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -404px -214px;
  width: 30px;
  height: 30px;
}

.active .left-menu__expert-etalon-pro-icon, .left-menu__list-item:hover .left-menu__expert-etalon-pro-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -419px -108px;
  width: 30px;
  height: 30px;
}

.active .left-menu__m-user-icon, .left-menu__list-item:hover .left-menu__m-user-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -471px -251px;
  width: 28px;
  height: 28px;
}

.active .left-menu__m-settings-icon, .left-menu__list-item:hover .left-menu__m-settings-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -427px -329px;
  width: 26px;
  height: 26px;
}

@media (max-width: 991px) {
  .left-menu__hidden-sm {
    display: none;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: white;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.sfPageContainer .header {
  position: static;
  padding-left: 100px;
}

.header .nav li {
  vertical-align: middle;
}

.header .nav li a {
  padding: 0;
}

.header .nav li a:hover, .header .nav li a:focus {
  background: inherit;
}

.header a::-moz-focus-inner {
  border: 0;
}

.header a:hover, .header a:focus {
  outline: 0;
}

.header__menu {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  padding-left: 80px;
  padding-right: 40px;
}

.header__menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__menu-subitem {
  width: 100%;
}

.header__first-item {
  width: 200px;
}

.header__middle-item {
  flex-grow: 1;
}

.header__last-item {
  width: 600px;
  min-width: 500px;
  justify-content: flex-end;
}

.header__submenu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header__submenu-item {
  white-space: nowrap;
}

.header__submenu-item-a {
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 3px;
  display: flex;
  color: #000;
  font-weight: 600;
}

.header__submenu-item-a:hover, .header__submenu-item-a:focus {
  text-decoration: underline;
  color: #000;
}

.header__search-group {
  margin: 0 auto;
  width: 100%;
  max-width: 445px;
  display: flex;
}

.header__search-input {
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  padding-right: 30px;
  padding-left: 20px;
  width: 100%;
  max-width: 445px;
  height: 36px;
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
}

.header__search-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.header__search-button {
  margin-left: -38px;
  background: none;
  border: none;
  padding: 4px;
  padding-right: 12px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: block;
}

.header__search-icon {
  margin-top: 3px;
}

.header__applications-block {
  position: relative;
}

.header__applications-link-block {
  position: relative;
  z-index: 3;
}

.header__menu-app {
  position: absolute;
  top: -20px;
  left: -10px;
  display: none;
  overflow: hidden;
  padding: 10px !important;
}

.header__menu-app-top {
  position: relative;
  z-index: 2;
  width: 148px;
  height: 60px;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 -6px 12px -6px rgba(0, 0, 0, 0.12), -6px 0px 12px -6px rgba(0, 0, 0, 0.12), 6px 0px 12px -6px rgba(0, 0, 0, 0.12);
}

.header__menu-app-bottom {
  position: relative;
  top: -1px;
  left: 0;
  z-index: 1;
  padding: 20px 30px 16px 30px;
  min-width: 290px;
  border-radius: 10px;
  border-top-left-radius: 0;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #f1f1f1;
}

.header__menu-app-header-block {
  margin-bottom: 20px;
}

.header__menu-app-header {
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: black;
}

.header__menu-app-hr {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.header__menu-app-header-block-2 {
  margin-bottom: 15px;
}

.header__menu-app-header-2 {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: #999999;
}

.header__app-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header__app-menu-link {
  padding: 10px 0;
  display: block;
  text-decoration: none;
}

.header__app-menu-link:hover, .header__app-menu-link:focus {
  text-decoration: none;
}

.header__app-menu-link:hover .header__app-menu-label,
.header__app-menu-link:focus .header__app-menu-label {
  text-decoration: underline;
}

.header__app-menu-link-block {
  display: flex;
}

.header__app-menu-label {
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: black;
  display: block;
  margin-left: 8px;
  margin-top: 2px;
}

.header__menu-app-header-block-3 {
  margin-bottom: 6px;
}

.header__app-menu-dots-block {
  display: flex;
  justify-content: space-between;
}

.header__no-margin {
  margin: 0;
}

.header__app-menu-count-label {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: #7F7F7F;
}

.header__app-menu-dots {
  background-image: linear-gradient(to right, #d2d2d2 50%, white 0%);
  background-position: bottom;
  background-size: 4px 2px;
  background-repeat: repeat-x;
  flex-grow: 1;
  margin: 0 4px;
}

.header__menu-profile-block {
  display: none;
  position: absolute;
  left: 0;
  top: -9px;
  width: calc(100% + 30px);
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #f1f1f1;
}

.header__menu-profile-open {
  display: block;
}

.header__menu-profile-list-block {
  padding: 70px 30px 15px 30px;
}

.header__menu-profile-item {
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: black;
  display: block;
  padding: 10px 0;
  margin: 0;
  cursor: pointer;
}

.header__menu-profile-item:hover {
  color: black;
  text-decoration: underline;
}

.header__menu-profile-item-exit {
  color: #d0021b;
}

.header__menu-profile-item-exit:hover {
  color: #d0021b;
}

.header__label {
  display: block;
  margin-left: 10px;
  color: #000;
  font-weight: 600;
}

.header__user-name {
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 275px;
}

@media (max-width: 1600px) {
  .header__user-name {
    max-width: 235px;
  }
}

@media (max-width: 1585px) {
  .header__user-name {
    max-width: 150px;
  }
}

@media (max-width: 1430px) {
  .header__user-name {
    max-width: 120px;
  }
}

@media (max-width: 1060px) {
  .header__user-name {
    max-width: 85px;
  }
}

.header__user-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: 37px auto;
  background-position: top center;
  background-repeat: no-repeat;
  display: inline-block;
}

.header__user-block {
  padding-left: 25px;
  position: relative;
}

.header__user-link {
  display: flex;
  position: relative;
  z-index: 1;
}

.header__border-left {
  border-left: 1px solid #e9e9e9;
  height: 30px;
  position: absolute;
}

.header__logo-block {
  height: 74px;
  display: block;
  margin-left: 3px;
}

.header__logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -173px;
  width: 197px;
  height: 73px;
}

.header__search-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -430px -148px;
  width: 20px;
  height: 20px;
}

.header__expert-etalon-pro-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -225px -399px;
  width: 20px;
  height: 20px;
}

.header__applications-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -280px -148px;
  width: 20px;
  height: 20px;
}

.header__valisiy-petrov-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -149px -329px;
  width: 30px;
  height: 30px;
  width: 30px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.header__MortgageRequest-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -255px -148px;
  width: 20px;
  height: 20px;
}

.header__ChangeProfileRequest-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -325px -399px;
  width: 20px;
  height: 20px;
}

.header__FixationRequest-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -175px -399px;
  width: 20px;
  height: 20px;
}

.header__BrowsingRequest-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -448px -370px;
  width: 20px;
  height: 20px;
}

.header__dd_promo_requests-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -355px -148px;
  width: 20px;
  height: 20px;
}

.header__ReservationRequest-icon {
  display: block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -330px -148px;
  width: 20px;
  height: 20px;
}

@media (max-width: 991px) {
  .header__hidden-sm {
    display: none;
  }
}

@media (max-width: 1125px) {
  .header__submenu-item-a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__user-block {
    padding-left: 15px;
  }
}

.submenu {
  font-size: 14px;
  color: black;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
}

.submenu a::-moz-focus-inner {
  border: 0;
}

.submenu a:hover, .submenu a:focus {
  outline: 0;
}

.submenu__header {
  font-size: 34px;
  font-weight: bold;
  line-height: 40px;
  padding-top: 30px;
  padding-left: 40px;
}

.submenu__list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 40px;
  display: flex;
}

.submenu__list-item {
  display: block;
  padding-right: 35px;
}

.submenu__list-link {
  color: black;
  padding-top: 15px;
  padding-bottom: 10px;
  display: block;
  text-decoration: none;
  border-bottom: 3px solid white;
  white-space: nowrap;
}

.submenu__list-link:hover {
  border-bottom: 3px solid #1b47bd;
  text-decoration: none;
  color: black;
}

.submenu__list-link:focus {
  text-decoration: none;
  color: black;
}

.submenu__list-item--active .submenu__list-link {
  font-weight: bold;
  border-bottom: 3px solid #1b47bd;
  padding-bottom: 11px;
}

.submenu__dropdown-menu {
  position: relative;
}

.submenu__dropdown-title {
  line-height: 17px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: black;
}

.submenu__dropdown-menu-header {
  display: flex;
  padding-bottom: 12px;
  padding-top: 20px;
  padding-left: 30px;
  cursor: pointer;
}

.submenu__dropdown-list-block {
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.submenu__dropdown-list {
  overflow: hidden;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.1);
}

.submenu__dropdown-list-item:first-child .submenu__dropdown-list-link {
  padding-top: 15px;
}

.submenu__dropdown-list-item:last-child .submenu__dropdown-list-link {
  padding-bottom: 15px;
}

.submenu__dropdown-list-link {
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: black;
  text-decoration: none;
  padding: 9px 17px;
  display: block;
  border-left: 3px solid transparent;
}

.submenu__dropdown-list-link:hover, .submenu__dropdown-list-link:focus {
  text-decoration: none;
  background-color: #f2f4fb;
  color: black;
}

.submenu__list-item--active .submenu__dropdown-list-link {
  font-weight: bold;
  background-color: #f2f4fb;
  border-left: 3px solid #1b47bd;
  color: black;
}

.submenu__caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 11px;
  margin-top: 8px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 0;
  border-bottom: 4px dashed;
  content: "";
  color: #002f6d;
}

.submenu__caret-collapse {
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-bottom: none;
}

.submenu__hide {
  display: none;
}

@media (max-width: 991px) {
  .submenu__hidden-sm {
    display: none;
  }
  .submenu__header {
    padding-top: 20px;
    padding-left: 20px;
  }
  .submenu__dropdown-menu-header {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .submenu__visible-sm {
    display: none;
  }
}

.content__padding-top-md {
  padding-top: 75px;
}

.content__padding-left-md {
  padding-left: 80px;
}

.content__padding {
  padding: 30px 40px;
}

@media (max-width: 991px) {
  .content__padding-top-md {
    padding-top: 0;
  }
  .content__padding-left-md {
    padding-left: 0;
  }
  .content__padding {
    padding: 20px;
  }
}

.content__columns-block {
  display: flex;
}

.content__column-content {
  width: 74%;
  padding-right: 30px;
}

.content__column-anchor-navigation {
  min-width: 285px;
  width: 26%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .content__column-anchor-navigation {
    display: none;
  }
  .content__column-content {
    width: 100%;
  }
}

.content .sfContentBlock img, .content .CitiesContent img {
  max-width: 100%;
}

.header-mobile {
  height: 54px;
  position: relative;
  z-index: 2;
}

.sfPageContainer .header-mobile {
  display: block !important;
  margin-top: 70px;
  margin-left: 80px;
}

.sfPageContainer .header-mobile .navbar-collapse.collapse {
  display: block !important;
}

.sfPageContainer .header-mobile {
  height: auto;
}

.sfPageContainer .header-mobile__position-fixed-1 {
  position: static;
}

.sfPageContainer .header-mobile__position-fixed-2 {
  position: static;
  height: auto;
}

.header-mobile .navbar-collapse.collapse {
  display: none !important;
}

.header-mobile .navbar-collapse.collapse.in {
  display: block !important;
}

.header-mobile .navbar-toggle {
  display: block;
  margin: 0;
  margin-left: -10px;
  border-color: transparent;
}

.header-mobile .navbar-toggle:hover, .header-mobile .navbar-toggle:focus {
  background-color: #00224f !important;
  border-color: #001736;
}

.header-mobile .navbar-toggle .icon-bar {
  background-color: white;
}

.header-mobile .dropdown-menu {
  margin-left: 20px;
  width: calc(100% - 20px) !important;
  background-color: #002553 !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: -6px 6px 12px rgba(0, 0, 0, 0.275) !important;
  border: none;
  position: absolute !important;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.header-mobile .dropdown-menu > li {
  margin-top: 1px;
  padding-left: 0px;
}

.header-mobile .navbar-collapse {
  padding: 0;
  border-top: none;
}

.header-mobile .dropdown-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  display: block !important;
}

.header-mobile .open > .dropdown-menu {
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
}

.header-mobile__position-fixed-1 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.header-mobile__position-fixed-2 {
  position: fixed;
  left: 0;
  top: 54px;
  height: 100%;
  overflow: hidden;
}

.header-mobile__list-wrapper {
  height: calc(100% - 54px);
  position: relative;
}

.header-mobile__list-wrapper.navbar-collapse.collapse {
  height: calc(100% - 54px) !important;
  overflow-x: hidden !important;
}

.header-mobile__is-touch-device {
  padding-right: 17px;
}

.header-mobile__list-block {
  min-height: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-mobile__scrollable {
  min-height: 100%;
}

.header-mobile__list-bottom {
  padding-top: 40px;
}

.header-mobile__columns {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  background-color: #002553;
}

.header-mobile__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-mobile__list {
  margin: 0;
  display: block;
  width: 100%;
  background-color: inherit;
}

.header-mobile__list-background {
  background-color: #002553;
  border-top: none;
}

.header-mobile__list-item {
  float: none !important;
  background-color: #002553;
}

.header-mobile__list-item a {
  padding: 8px 25px 8px 20px !important;
  line-height: 30px !important;
  background-color: #002553 !important;
}

.header-mobile__list-item a:after {
  display: block;
  clear: both;
  content: "";
}

.header-mobile__list-item.active, .header-mobile__list-item:hover, .header-mobile__list-item:focus {
  background-color: #001736;
}

.header-mobile__list-item.active > a, .header-mobile__list-item:hover > a, .header-mobile__list-item:focus > a {
  background-color: #001736 !important;
}

.header-mobile__icon {
  float: left;
  margin-top: 6px;
}

.header-mobile__label {
  float: left;
  font-size: 16px;
  color: #cedbf2;
  line-height: 30px;
  padding-left: 18px;
}

.header-mobile .caret {
  margin-top: 0px;
  margin-left: 8px;
  color: white;
}

.header-mobile__logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -255px -43px;
  width: 146px;
  height: 54px;
}

.header-mobile__logo-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-mobile__user-avatar-wrapper {
  position: relative;
}

.header-mobile__user-avatar-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.header-mobile__user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: 30px auto;
  background-position: top center;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
}

.header-mobile__user-menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: -15px;
  height: 150px;
  width: 110px;
}

.header-mobile__user-top-wrapper {
  position: absolute;
  top: 6px;
  right: 5px;
}

.header-mobile__user-bottom-wrapper {
  position: absolute;
  top: 47px;
  right: 5px;
}

.header-mobile__user-top-block {
  width: 50px;
  height: 42px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #f1f1f1;
  border-bottom: none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.header-mobile__user-bottom-block {
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
}

.header-mobile__user-profile-menu {
  padding: 15px 20px 10px 20px;
}

.header-mobile__user-profile-link {
  line-height: 27px;
  padding-bottom: 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.header-mobile__user-profile-link:hover, .header-mobile__user-profile-link:focus {
  color: black;
  text-decoration: underline;
}

.header-mobile__user-profile-link-logout {
  color: #d0021b;
}

.header-mobile__user-profile-link-logout:hover, .header-mobile__user-profile-link-logout:focus {
  color: #d0021b;
  text-decoration: underline;
}

.header-mobile__valisiy-petrov-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -149px -329px;
  width: 30px;
  height: 30px;
  background-size: inherit;
}

.header-mobile__list .left-menu__find-apartment-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -299px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__applications-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -92px -447px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__applications-responsible-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -23px -447px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__info-for-sales-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -138px -447px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__interaction-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -475px -399px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__mortgage-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -23px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__about-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -69px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__news-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -115px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__contacts-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -161px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__expert-etalon-pro-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -207px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .left-menu__m-user-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -460px -424px;
  width: 18px;
  height: 18px;
  float: left;
}

.header-mobile__list .left-menu__m-settings-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -345px -424px;
  width: 18px;
  height: 18px;
  float: left;
}

.header-mobile__list .active .left-menu__find-apartment-icon, .header-mobile__list-item:hover .left-menu__find-apartment-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -276px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__applications-icon, .header-mobile__list-item:hover .left-menu__applications-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -115px -447px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__applications-responsible-icon, .header-mobile__list-item:hover .left-menu__applications-responsible-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -447px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__info-for-sales-icon, .header-mobile__list-item:hover .left-menu__info-for-sales-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -482px -291px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__interaction-icon, .header-mobile__list-item:hover .left-menu__interaction-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__mortgage-icon, .header-mobile__list-item:hover .left-menu__mortgage-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -46px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__about-icon, .header-mobile__list-item:hover .left-menu__about-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -92px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__news-icon, .header-mobile__list-item:hover .left-menu__news-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -138px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__contacts-icon, .header-mobile__list-item:hover .left-menu__contacts-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -184px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__expert-etalon-pro-icon, .header-mobile__list-item:hover .left-menu__expert-etalon-pro-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -230px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__m-user-icon, .header-mobile__list-item:hover .left-menu__m-user-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -437px -424px;
  width: 18px;
  height: 18px;
}

.header-mobile__list .active .left-menu__m-settings-icon, .header-mobile__list-item:hover .left-menu__m-settings-icon {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -322px -424px;
  width: 18px;
  height: 18px;
}

@media (min-width: 992px) {
  .header-mobile__visible-sm {
    display: none;
  }
}

.footer {
  padding-left: 83px;
  padding-bottom: 30px;
  border-top: 1px solid #e8e8e8;
}

.footer a::-moz-focus-inner {
  border: 0;
}

.footer a:hover, .footer a:focus {
  outline: 0;
}

.footer__columns {
  display: table;
  width: 100%;
}

.footer__column {
  display: table-cell;
  vertical-align: top;
}

.footer__logo-column {
  min-width: 200px;
  max-width: 200px;
  padding-left: -27px;
}

.footer__navigation-column {
  min-width: 337px;
  max-width: 420px;
}

.footer__contacts-column {
  min-width: 360px;
  max-width: 420px;
  padding-left: 90px;
}

.footer__share-column {
  min-width: 200px;
  max-width: 250px;
  padding-right: 40px;
}

.footer__header {
  font-size: 10px;
  font-weight: 600;
  color: black;
  opacity: 0.49;
  margin-top: 45px;
  margin-bottom: 15px;
}

.footer__phone, .footer__link {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.footer__link:hover, .footer__link:focus {
  color: black;
}

.footer__city {
  font-size: 10px;
  color: #888;
  margin-bottom: 30px;
}

.footer__navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  height: 120px;
}

.footer__navigation-list a {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 15px 8px 0;
}

.footer__navigation-item a {
  text-decoration: none;
}

.footer__navigation-item a:hover {
  text-decoration: underline;
}

.footer__contacts {
  display: flex;
  flex-wrap: wrap;
  height: 120px;
  justify-content: space-between;
  align-content: space-between;
}

.footer__share {
  display: flex;
}

.footer__share-block {
  display: flex;
  justify-content: center;
}

.footer__share-item {
  padding-right: 5px;
}

.footer__share-label {
  font-size: 12px;
  line-height: normal;
  font-weight: 600;
  color: #828282;
  padding-left: 5px;
}

.footer__share-bottom {
  margin-top: 30px;
  justify-content: space-between;
  max-width: 370px;
}

.footer__share-bottom-item {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.footer__share-bottom-item:last-child {
  padding-right: 0;
}

.footer__share-bottom-item:hover {
  text-decoration: none;
}

.footer__logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -251px;
  width: 197px;
  height: 73px;
}

.footer__facebook-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -261px -370px;
  width: 24px;
  height: 24px;
}

.footer__google-plus-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -87px -370px;
  width: 24px;
  height: 24px;
}

.footer__moy-mir-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -370px;
  width: 24px;
  height: 24px;
}

.footer__odnoklassniki-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -458px -329px;
  width: 24px;
  height: 24px;
}

.footer__twitter-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -203px -370px;
  width: 24px;
  height: 24px;
}

.footer__vk-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -116px -370px;
  width: 24px;
  height: 24px;
}

.footer__telegram-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -232px -370px;
  width: 24px;
  height: 24px;
}

@media (min-width: 1500px) {
  .footer__share-column {
    max-width: 370px;
    min-width: 230px;
  }
  .footer__share-bottom {
    width: 224px;
  }
}

@media (min-width: 1500px) {
  .footer__contacts-column {
    max-width: 400px;
  }
  .footer__share-column {
    min-width: 280px;
  }
  .footer__contacts-column {
    padding-left: 40px;
  }
  .footer__navigation-column {
    max-width: 430px;
  }
}

@media (min-width: 1400px) {
  .footer__contacts-column {
    padding-left: 40px;
  }
}

@media (max-width: 1399px) {
  .footer__contacts-column {
    min-width: 350px;
    max-width: 350px;
    padding-left: 20px;
  }
  .footer__navigation-column {
    max-width: 430px;
    min-width: 370px;
  }
  .footer__share-column {
    padding-right: 20px;
  }
}

@media (max-width: 1250px) {
  .footer__contacts-column {
    padding-left: 20px;
  }
}

@media (max-width: 1200px) {
  .footer {
    padding-left: 120px;
  }
  .footer__logo-column {
    display: none;
  }
}

@media (max-width: 991px) {
  .footer__hidden-sm {
    display: none;
  }
  .footer__columns {
    flex-direction: row;
    justify-content: center;
    display: flex;
  }
  .footer__column {
    display: block;
    flex-grow: 1;
  }
  .footer__logo-column {
    display: none;
  }
  .footer__contacts-column {
    padding-left: 0;
  }
  .footer__navigation-list, .footer__contacts {
    height: auto;
  }
  .footer__contacts {
    display: block;
  }
  .footer__logo-column, .footer__navigation-column, .footer__contacts-column, .footer__share-column {
    min-width: auto;
    max-width: none;
  }
  .footer {
    padding-left: 80px;
  }
}

@media (max-width: 800px) {
  .footer {
    padding-left: 40px;
  }
  .footer__share-block {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .footer__columns {
    flex-direction: column;
  }
}

.anchor-navigation {
  position: relative;
  margin-top: 60px;
}

.anchor-navigation a::-moz-focus-inner {
  border: 0;
}

.anchor-navigation a:hover, .anchor-navigation a:focus {
  outline: 0;
}

.anchor-navigation__list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 230px;
}

.anchor-navigation__item {
  margin-bottom: 1px;
}

.anchor-navigation__item a {
  padding: 9px;
  padding-left: 25px;
  padding-right: 20px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.anchor-navigation__item a:hover, .anchor-navigation__item a:focus {
  text-decoration: none;
}

.anchor-navigation__item:hover, .anchor-navigation__item.active {
  background-color: #00b3e3;
  transform: skew(-40deg);
}

.anchor-navigation__item:hover a, .anchor-navigation__item.active a {
  color: white;
  text-decoration: none;
  transform: skew(40deg);
  font-family: "Proxima Nova Th";
  font-weight: normal;
}

.modal-backdrop.fade {
  z-index: 2040;
}

.modal {
  z-index: 2050;
}

.modal-etalon-pro .modal-dialog {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
}

.modal-etalon-pro .modal-content {
  border-radius: 2px;
  box-shadow: none;
  border: none;
}

.modal-etalon-pro .modal-header {
  background-color: white;
  color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom: none;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  padding: 6px 20px 4px 20px;
}

.modal-etalon-pro .modal-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 2.5;
  text-align: left;
  color: black;
}

.modal-etalon-pro button.close {
  padding: 0;
  cursor: pointer;
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -308px -447px;
  width: 16px;
  height: 16px;
  border: 0;
}

.modal-etalon-pro .close {
  color: #002f6d;
  float: right;
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  margin-top: 10px;
}

.modal-etalon-pro .close:hover {
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  color: black;
  text-shadow: 3px 0px 5px rgba(150, 150, 150, 0.3);
}

.modal-etalon-pro .modal-body {
  padding: 20px;
  color: black;
}

.modal-etalon-pro .modal-footer {
  padding: 20px 30px;
  background: linear-gradient(to bottom, #ecf2fd 0%, #f4f7fe 100%);
}

.modal-etalon-pro .modal-flex {
  display: flex;
  justify-content: flex-end;
}

.modal-etalon-pro .modal-flex-wrap {
  flex-wrap: wrap;
}

.modal-etalon-pro .modal-footer-flex-left {
  flex-grow: 1;
  display: flex;
}

.modal-etalon-pro .modal-submit-button {
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 3px;
  background-color: #0059a0;
  border: 1px solid #2e6da4;
  line-height: 17px;
  padding: 8px 20px;
  cursor: pointer;
}

.modal-etalon-pro .modal-submit-button:hover {
  background-color: #004f8e;
}

.modal-etalon-pro .modal-close-button {
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  color: #002f69;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #cedbf2;
  padding: 8px 20px;
  cursor: pointer;
  margin-left: 20px;
  display: inline-block;
}

.modal-etalon-pro .modal-close-button:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}

.modal-etalon-pro .link-button-black {
  color: black;
  text-decoration: none;
  border-bottom: 1px dotted black;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
}

.modal-etalon-pro .link-button-black:hover {
  color: #555;
  border-bottom: 1px dotted #555;
}

.modal-etalon-pro .modal-link-button {
  font-size: 14px;
  color: #337ab7;
  font-weight: 600;
  letter-spacing: 0.3px;
  cursor: pointer;
  padding: 5px 0 0 0;
  border-bottom: 1px solid #337ab7;
  text-decoration: none;
  display: inline-block;
}

@media (min-width: 768px) {
  .modal-etalon-pro .modal-dialog {
    margin: 80px auto;
  }
}

@media (max-width: 370px) {
  .modal-etalon-pro .modal-submit-button,
  .modal-etalon-pro .modal-close-button {
    display: block;
    width: 100%;
    margin: 0;
  }
  .modal-etalon-pro .modal-close-button {
    margin-top: 15px;
  }
  .modal-etalon-pro .modal-flex {
    display: block;
  }
}

.login {
  min-width: 320px;
  /*&__submit-btn {
		height: 42px;
		background-color: #0059a0;
		border: 1px solid #0059a0;
		border-radius: 5px;

		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.3px;
		color: #ffffff;

		padding: 13px 40px;
		text-align: center;
		display: inline-block;
	}*/
}

.login__container {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .login__container {
    width: calc(100% - 140px);
  }
}

@media (min-width: 992px) {
  .login__container {
    width: calc(100% - 140px);
  }
}

@media (min-width: 1260px) {
  .login__container {
    width: 1120px;
  }
}

.login__login-bg {
  background-image: url("../Images/main-bg.jpg");
  min-height: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: top right;
  background-size: auto 100%;
}

.login__signup-bg {
  background-image: url("../Images/sign_up_background.jpg");
  min-height: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: top center;
  background-size: auto 100%;
  background-color: #7998bb;
  position: relative;
}

.login__signup-bg:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("../Images/lines_for_sign_up_background.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 474px;
  height: 305px;
}

@media (min-width: 1600px) {
  .login__signup-bg {
    background-size: 100% auto;
  }
}

@media (max-width: 1600px) {
  .login__signup-bg:after {
    width: calc(100% / 2 - 630px / 2);
  }
}

@media (max-width: 989px) {
  .login__signup-bg:after {
    background-image: none;
  }
}

.login__regdone-bg-size {
  background-size: 100% auto;
}

@media (max-aspect-ratio: 1600 / 1450) {
  .login__regdone-bg-size {
    background-size: auto 100%;
  }
}

@media (min-aspect-ratio: 1600 / 1450) {
  .login__regdone-bg-size {
    background-size: 100% auto;
  }
}

.login__login-logo-block {
  margin-top: 130px;
  margin-bottom: 82px;
  display: none;
}

.login__signup-logo-block {
  margin-top: 60px;
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: 630px;
  padding: 0 15px;
}

.login__login-logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -43px;
  width: 250px;
  height: 60px;
}

.login__signup-logo-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -108px;
  width: 250px;
  height: 60px;
}

.login .authIn {
  margin: 0 5px;
}

.login__oAuthProviders-block {
  margin-top: 16px;
}

.login__block {
  display: block;
  max-width: 340px;
}

.login__header {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: left;
  color: black;
}

.login__link {
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #337ab7;
  text-decoration: none;
  border-bottom: 1px solid #337ab7;
}

.login__link:hover, .login__link:focus, .login__link:active {
  text-decoration: none;
}

.login__link:link, .login__link:visited {
  color: #337ab7;
}

.login__hr {
  border-top: 1px solid #e9e9e9;
  height: 0px;
}

.login__text-danger-block {
  padding-top: 20px;
}

.login__text {
  line-height: 17px;
  font-size: 14px;
  text-align: left;
  color: black;
}

.login__flex {
  display: flex;
  flex-wrap: wrap;
}

.login__justify-between {
  justify-content: space-between;
}

.login__align-items-center {
  align-items: center;
}

.login__font-size-0 {
  font-size: 0;
}

.login__hide {
  display: none;
}

.login__text-center {
  text-align: center;
}

.login__text-justify {
  text-align: justify;
}

.login__form-block {
  padding-top: 20px;
  padding-bottom: 12px;
}

.login__remember-me-block {
  padding-bottom: 28px;
}

.login__form-group {
  padding-bottom: 20px;
}

.login__label {
  line-height: 20px;
  font-size: 16px;
  text-align: left;
  color: #7F7F7F;
  font-weight: normal;
  display: block;
  margin-bottom: 6px;
  margin-bottom: 0;
  display: inline-block;
}

.login__label-small {
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: #7F7F7F;
  font-weight: normal;
  display: block;
  margin-bottom: 6px;
  margin-bottom: 0;
  display: inline-block;
}

.login__input {
  background-color: #ffffff;
  border: solid 1px #d0d1d4;
  border-radius: 0;
  height: 40px;
  width: 100%;
  line-height: 17px;
  font-size: 14px;
  color: black;
  padding: 12px 14px;
}

.login__input-danger {
  color: #d0021b;
}

.login__social-block {
  padding-bottom: 30px;
  min-width: 320px;
}

.login__social-helper-block {
  padding-top: 30px;
}

.login__help-block {
  cursor: pointer;
  margin-left: 10px;
}

.login__tooltip-wrapper {
  position: relative;
  display: none;
}

.login__tooltip-triangle {
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  background-color: white;
  position: absolute;
  left: 27px;
  top: -24px;
  z-index: 1;
  box-shadow: -2px 2px 9px -2px rgba(0, 0, 0, 0.17);
}

.login__tooltip-block {
  position: absolute;
  left: 35px;
  top: -70px;
  cursor: auto;
  padding: 20px;
  width: 420px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.17);
}

.login__tooltip-content {
  position: relative;
}

.login__tooltip-close-block {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.login__tooltip-header {
  line-height: 18px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: black;
  padding-bottom: 16px;
}

.login__tooltip-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align: left;
  color: black;
}

.login__tooltip-list {
  margin: 0;
  padding: 0;
  padding-left: 0px;
  list-style: none;
}

.login__tooltip-list-item {
  padding-left: 10px;
}

.login__register-block {
  padding: 30px 0;
}

.login__register-link-block {
  padding-top: 10px;
}

.login__reset-password-input-block {
  padding-bottom: 30px;
}

.login__alert-danger {
  border-radius: 2px;
  background: linear-gradient(to bottom, #fdecec 0%, #fef4f4 100%);
  box-shadow: 1px 0 20px 0 rgba(150, 150, 150, 0.4);
  padding: 28px;
  line-height: 17px;
  font-size: 14px;
  text-align: left;
  color: #d0021b;
  border: 1px solid #ffe5e5;
  margin-top: 35px;
}

.login__alert-info {
  border-radius: 2px;
  background: linear-gradient(to bottom, #ecf2fd 0%, #f4f7fe 100%);
  box-shadow: 1px 0 20px 0 rgba(180, 180, 180, 0.3);
  padding: 28px;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: black;
  border: 1px solid #e1e9f7;
  margin-bottom: 30px;
}

.login__change-password-block {
  margin-bottom: 30px;
}

.login__regdone-block {
  max-width: 630px;
  margin: 25px auto;
  padding: 0 15px;
}

.login__regdone-bg {
  background-color: white;
}

.login__regdone-content {
  padding: 0 20px;
}

.login__regdone-img {
  background-image: url("../Images/thanks_for_registering.png");
  background-repeat: no-repeat;
  width: 248px;
  height: 220px;
  margin: 0 auto;
}

.login__regdone-img-group {
  padding-top: 90px;
  padding-bottom: 40px;
}

.login__regdone-header-group {
  margin-bottom: 20px;
}

.login__regdone-text-group {
  padding-bottom: 65px;
  margin: 0 20px;
}

.login__regdone-text {
  line-height: 20px;
  font-size: 14px;
  color: black;
}

.login__facebook-icon {
  cursor: pointer;
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -445px -173px;
  width: 36px;
  height: 36px;
}

.login__vk-icon {
  cursor: pointer;
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -453px -43px;
  width: 36px;
  height: 36px;
}

.login__odnoklassniki-icon {
  cursor: pointer;
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: 0px -329px;
  width: 36px;
  height: 36px;
}

.login__google-icon {
  cursor: pointer;
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -404px -173px;
  width: 36px;
  height: 36px;
}

.login__help-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -455px -148px;
  width: 20px;
  height: 20px;
}

.login__tooltip-close-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -287px -447px;
  width: 16px;
  height: 16px;
}

@media (max-width: 1091px) {
  .login__login-bg {
    background-image: none;
  }
  .login__login-logo-block {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .login__help-block {
    position: relative;
  }
  .login__tooltip-wrapper {
    position: absolute;
    left: -330px;
    top: 100px;
  }
  .login__tooltip-triangle {
    display: none;
  }
}

@media (max-width: 450px) {
  .login__tooltip-block {
    width: 280px;
  }
}

.signup {
  /*background: url('../Images/sign_up_background.png');
	padding-top: 60px;
	height: 1450px;*/
  /*.logo {
		background: url(../Images/logo_registration_form.png);
		height: 60px;
		width: 250px;
	}*/
  /*
	#BirthDateStr {
		// width: 160px;
		// background: url('../Images/date_field_bg.png');
		// height: 38px;
		@include sprite($signup-date-field-bg);

		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
		color: black;
		font-size: 14px;
		line-height: 17px;
		font-family: "Proxima Nova Rg";
		letter-spacing: 0.8px;
	}
	*/
  /*#StartWork {
		width: 160px;
		height: 38px;
		background-color: #ffffff;
		border: solid 1px #d0d1d4;
		border-radius: 0;
	}*/
}

.signup .container-fluid {
  width: 660px;
}

.signup .col-md-4, .signup .col-md-8, .signup .col-md12 {
  padding: 0;
}

.signup .row {
  margin-left: 0;
  margin-right: 0;
}

.signup__checkbox-wrapper {
  padding-top: 7px;
  padding-bottom: 7px;
}

.signup__text {
  line-height: 17px;
  font-size: 14px;
  color: black;
  font-weight: normal;
}

.signup__flex {
  display: flex;
}

.signup .pageTitle {
  font-family: "Proxima Nova Rg";
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.signup .signup-form {
  margin-top: 30px;
  padding-top: 50px;
  background: #fff;
  overflow: auto;
  padding-left: 50px;
  padding-right: 65px;
}

.signup .signup-form input.form-control[type="text"] {
  height: 38px;
  background-color: #ffffff;
  border: solid 1px #d0d1d4;
  border-radius: 0;
}

.signup .signup-form .control-label {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  text-align: left;
  padding-top: 10px;
  line-height: 17px;
}

.signup .panel {
  border: 0;
}

.signup .formFooter {
  background-image: linear-gradient(to top, rgba(103, 143, 255, 0), rgba(23, 99, 215, 0.05));
  background-color: #fff;
  padding: 30px 65px 40px 50px;
  margin-bottom: 95px;
}

.signup .panel-heading {
  display: inline;
  padding: 0;
}

.signup .formTitle {
  margin-top: 30px;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Proxima Nova Rg";
  font-weight: 600;
  color: #002f6d;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 20px;
  line-height: 20px;
  padding-bottom: 5px;
}

.signup #bithDateStr {
  width: 160px;
  height: 38px;
  background-color: #ffffff;
  border: solid 1px #d0d1d4;
  border-radius: 0;
}

.signup .text-box {
  font-family: "Proxima Nova Rg";
  letter-spacing: 0.8px;
  line-height: 17px;
  color: black;
}

.signup #collapseOne {
  margin-top: 15px;
}

.signup .signup-button {
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-top: 30px;
}

.signup__dropdown-dateYear {
  width: 160px;
}

.signup__help-wrapper {
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
}

.signup__help-block {
  position: absolute;
  bottom: 60px;
  left: 126px;
  width: 100%;
  padding: 25px 40px 20px 20px;
  background-color: white;
  border: 1px solid #d0d1d4;
  border-radius: 5px;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.17);
  display: none;
}

.signup__help-close {
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -287px -447px;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.signup__label {
  flex-shrink: 30;
}

@media (min-width: 991px) {
  .signup .col-md-8 {
    width: 72%;
  }
  .signup .col-md-4 {
    width: 28%;
  }
}

@media (max-width: 990px) {
  .signup .container-fluid {
    width: 100%;
  }
  .signup .signup-form, .signup .formFooter {
    padding-left: 30px;
    padding-right: 30px;
  }
  .signup__help-block {
    bottom: 60px;
    left: -15px;
    width: calc(100% + 30px);
  }
}

@media (max-width: 500px) {
  .signup__checkbox-block {
    display: block;
  }
  .signup__help-block {
    bottom: 75px;
    left: -60px;
    width: calc(100% + 120px);
  }
}

@media (max-width: 420px) {
  .signup .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
  .signup .signup-button {
    padding: 13px 20px;
  }
}

@media (max-width: 350px) {
  .signup .g-recaptcha {
    transform: scale(0.68);
    transform-origin: 0 0;
  }
}

.news {
  /*&__tooltip-close-icon {
		display: inline-block;
		@include sprite($news-icon);
	}*/
  /*&__pagination li:first-child &__pagination-link {
		padding-left: 0;
	}
	&__pagination li:last-child &__pagination-link {
		padding-right: 0;
	}*/
}

.news__list-item {
  margin-bottom: 30px;
}

.news__date-block {
  margin-bottom: 4px;
}

.news__date {
  line-height: 17px;
  font-size: 14px;
  color: #7F7F7F;
}

.news__header-link-block {
  margin-bottom: 6px;
}

.news__header-link {
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.news__header-link:hover, .news__header-link:focus {
  color: black;
  text-decoration: underline;
}

.news__tag-link {
  line-height: 17px;
  font-size: 14px;
  color: #9fa9bc;
}

.news__tag-list-border {
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  margin: 0 -40px 30px -40px;
  padding: 0 40px 0px 40px;
}

.news__tag-list-wrapper {
  height: 70px;
  overflow-y: hidden;
  overflow-x: visible;
}

.news__tag-list-block {
  padding-top: 20px;
  padding-right: 95px;
  position: relative;
  display: inline-block;
  font-size: 0;
  margin-bottom: -7px;
}

.news__tag-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.news__tag-list-item {
  padding-right: 10px;
  padding-bottom: 20px;
  display: block;
  float: left;
}

.news__tag-list-item:after {
  content: "";
  display: block;
  clear: both;
}

.news__tag-list-link {
  padding: 6px 14px 7px 14px;
  border-radius: 15px;
  background-color: rgba(206, 219, 242, 0.4);
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  display: block;
  white-space: nowrap;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.news__tag-list-link:hover, .news__tag-list-link:focus {
  color: black;
  text-decoration: underline;
}

.news__tag-list-link:after {
  content: "";
  display: block;
  clear: both;
}

.news__tag-list-link::-moz-focus-inner {
  border: 0;
}

.news__tag-list-link:hover, .news__tag-list-link:focus {
  outline: 0;
}

.news__tag-list-link-label {
  display: block;
  float: left;
}

.news__tag-list-link-current {
  background-color: #1a48bd;
  color: white;
}

.news__tag-list-link-current:hover, .news__tag-list-link-current:focus {
  color: white;
}

.news__tag-list-reset {
  float: left;
  display: block;
  margin-left: 7px;
  margin-top: 1px;
  margin-right: -5px;
  padding: 0.5px 3.2px;
  border-radius: 50%;
  line-height: 14px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #1a48bd;
}

.news__tag-list-label {
  line-height: 17px;
  font-size: 14px;
  color: #7F7F7F;
  padding-top: 6px;
  padding-bottom: 7px;
}

.news__header-top {
  line-height: 40px;
  font-size: 34px;
  font-weight: bold;
  color: black;
}

.news__tag-show-all {
  position: absolute;
  top: 23px;
  right: 0;
  padding: 4px 0 7px 0;
  margin-right: 0;
  display: none;
}

.news__tag-hide-all {
  padding: 7px 10px 7px 0;
  margin-right: 0;
  display: none;
}

.news__link-back {
  border-bottom: none;
}

.news__no-underscore {
  text-decoration: none;
}

.news__no-underscore:hover, .news__no-underscore:focus {
  text-decoration: none;
}

.news__header-detail {
  line-height: 28px;
  font-size: 26px;
  font-weight: bold;
  color: black;
  margin: 30px 0 20px 0;
}

.news__text {
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.news__text p {
  margin: 0 0 20px 0;
}

.news__detail-tag-block {
  padding-top: 26px;
  padding-bottom: 37px;
}

.news__detail-tag-label {
  line-height: 17px;
  font-size: 14px;
  color: #7F7F7F;
}

.news__detail-tag {
  line-height: 17px;
  font-size: 14px;
  color: #9fa9bc;
}

.news__detail-date {
  line-height: 17px;
  font-size: 14px;
  color: #7F7F7F;
  margin-bottom: 10px;
}

.news__pagination-block {
  padding-top: 20px;
}

.news__pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.news__pagination-link {
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  color: #003269;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.news__pagination-link:hover, .news__pagination-link:focus {
  text-decoration: none;
  color: #003269;
}

.news__pagination-link-current {
  border: 1px solid #cedbf2;
  border-radius: 5px;
}

.news__pagination-prev-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -489px -108px;
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.news__pagination-next-icon {
  display: inline-block;
  background-image: url(../sprite/sprite.png?20180208173545);
  background-position: -489px -123px;
  width: 10px;
  height: 10px;
  margin-left: 8px;
}

.news__pagination-arrow-current {
  opacity: 0.5;
}

.news__pagination-arrow-block {
  border: 1px solid #cedbf2;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .news__pagination-arrow-text {
    display: none;
  }
  .news__pagination-arrow-left-block {
    height: 35px;
    padding-right: 5px;
    padding-left: 12px;
  }
  .news__pagination-arrow-right-block {
    height: 35px;
    padding-right: 12px;
    padding-left: 5px;
  }
  .news__tag-list-block {
    padding-right: 0;
  }
}

.search__header {
  line-height: 28px;
  font-size: 26px;
  font-weight: bold;
  color: black;
  margin: 30px 0 20px 0;
}

.search__header-link-block {
  margin-bottom: 6px;
}

.search__header-link {
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.search__header-link:hover, .search__header-link:focus {
  color: black;
  text-decoration: underline;
}

.search__text {
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 6px;
}

.search__text p {
  margin: 0 0 20px 0;
}

.search__text-muted {
  line-height: 17px;
  font-size: 14px;
  color: #9fa9bc;
}

.search__text-muted:hover, .search__text-muted:focus {
  text-decoration: underline;
  color: #9fa9bc;
}

.search__list-item {
  margin-bottom: 30px;
}

.search__date-block {
  margin-bottom: 4px;
}

.search__date {
  line-height: 17px;
  font-size: 14px;
  color: #7F7F7F;
}

.search__header-link-block {
  margin-bottom: 6px;
}

.search__tag-link {
  line-height: 17px;
  font-size: 14px;
  color: #9fa9bc;
}

.search__link-back {
  border-bottom: none;
}

.search__no-underscore {
  text-decoration: none;
}

.search__no-underscore:hover, .search__no-underscore:focus {
  text-decoration: none;
}
