@import "../../sprite/_sprite.scss";

div.pickmeup {
	background-color: white;
	border-radius: 2px;
	background-color: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	border: 1px solid #cccccc;
	width: 278px;
	height: auto;
	padding: 0;

	&.pmu-view-months {
		width: 278px;

		.pmu-instance {
			padding: 5px 10px 10px 10px;
			> nav {
				margin-bottom: 5px;
			}
			nav {
				.pmu-next {
					margin-right: 15px;
				}
				.pmu-prev {
					margin-left: 15px;
				}
			}
		}
	}
	&.pmu-view-years {
		width: 278px;

		.pmu-instance {
			padding: 5px 10px 10px 10px;
			> nav {
				margin-bottom: 9px;
			}
			nav {
				.pmu-next {
					margin-right: 15px;
				}
				.pmu-prev {
					margin-left: 15px;
				}
			}
		}
	}

	.pmu-instance {
		width: auto;
		height: auto;
		padding: 5px 20px 20px 20px;

		.pmu-day-of-week {
			margin: 18px 0 0 0;
			font-size: 14px;
			line-height: 17px;
			color: #BDBDBD;

			* {
				width: 30px;
			}
		}

		.pmu-selected {
			background-color: #337ab7;
			color: white;
			border-radius: 50%;
		}

		.pmu-month {
			line-height: 17px;
			font-size: 14px;
			color: black;
			padding-top: 19px;
			&:hover {
				color: #333333;
			}
		}

		.pmu-days {
			font-size: 0;
			line-height: 0;

			.pmu-button {
				line-height: 30px;
				font-size: 14px;
				color: black;
				width: 30px;
				margin: 4px 2px;
				&:hover {
					background-color: #337ab7;
					border-radius: 50%;
					color: white;
				}
				&.pmu-disabled {
					cursor: default;
					color: #DDDDDD;
					background-color: inherit;
					&:hover {
						font-weight: normal;
					}
				}
			}
			.pmu-today {
				background-color: #edeef1;
				border-radius: 50%
			}
			.pmu-not-in-month {
				line-height: 30px;
				font-size: 14px;
				color: #7F7F7F;
			}
			.pmu-selected {
				background-color: #337ab7;
				border-radius: 50%;
				color: white;
			}
		}

		.pmu-months {
			.pmu-button {
				line-height: 30px;
				font-size: 14px;
				color: black;
				width: 50px;
				margin: 7px 7px;

				&:hover {
					background-color: #337ab7;
					border-radius: 15px;
					color: white;
					//font-weight: 600;
				}
				&.pmu-disabled {
					cursor: default;
					color: #DDDDDD;
					background-color: inherit;
					&:hover {
						font-weight: normal;
					}
				}
			}
			.pmu-selected {
				background-color: #337ab7;
				border-radius: 15px;
				color: white;
				font-weight: 600;
			}
		}

		.pmu-years {
			.pmu-button {
				line-height: 30px;
				font-size: 14px;
				color: black;
				width: 50px;
				margin: 8px 7px;

				&:hover {
					background-color: #337ab7;
					border-radius: 15px;
					color: white;
					//font-weight: 600;
				}
				&.pmu-disabled {
					cursor: default;
					color: #DDDDDD;
					background-color: inherit;
					&:hover {
						font-weight: normal;
					}
				}
			}
			.pmu-selected {
				background-color: #337ab7;
				border-radius: 15px;
				color: white;
				font-weight: 600;
			}
		}

		nav {
			justify-content: space-between;

			.pmu-next {
				margin-top: 15px;
				margin-right: 5px;
				color: transparent;
				display: none;
				@include sprite($control-arrow-right);
				&:hover {
					color: transparent;
					@include sprite($control-arrow-right);
				}
				&:after {
					content: "";
					display: block;
					width: 40px;
					height: 30px;
					margin-top: -39px;
					margin-left: -12px;
				}
			}
			.pmu-prev {
				margin-top: 15px;
				margin-left: 5px;
				color: transparent;
				display: none;
				@include sprite($control-arrow-left);
				&:hover {
					color: transparent;
					@include sprite($control-arrow-left);
				}
				&:after {
					content: "";
					display: block;
					width: 40px;
					height: 30px;
					margin-top: -39px;
					margin-left: -12px;
				}
			}
		}

		.pmu-not-in-month,
		.pmu-today,
		.pmu-selected {
			&:hover {
				background-color: #337ab7;
				border-radius: 50%;
				color: white;
			}
		}
		.pmu-not-in-month.pmu-selected {
			background-color: #337ab7;
			border-radius: 50%;
			color: white;
		}
	}
}

