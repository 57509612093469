@import "../../sprite/_sprite.scss";

.search {

	&__header {
		line-height: 28px;
		font-size: 26px;
		font-weight: bold;
		color: black;
		margin: 30px 0 20px 0;
	}
	&__header-link-block {
		margin-bottom: 6px;
	}
	&__header-link {
		line-height: 22px;
		font-size: 18px;
		font-weight: bold;
		color: black;
		text-decoration: none;
		&:hover, &:focus {
			color: black;
			text-decoration: underline;
		}
	}
	&__text {
		font-size: 16px;
		line-height: 20px;
		color: black;
		margin-bottom: 6px;
		p {
			margin: 0 0 20px 0;
		}
	}
	&__text-muted {
		line-height: 17px;
		font-size: 14px;
		color: #9fa9bc;
		&:hover, &:focus {
			text-decoration: underline;
			color: #9fa9bc;
		}
	}

	// Список новостей
	&__list-item {
		margin-bottom: 30px;
	}

	&__date-block {
		margin-bottom: 4px;
	}
	&__date {
		line-height: 17px;
		font-size: 14px;
		color: #7F7F7F;
	}

	&__header-link-block {
		margin-bottom: 6px;
	}


	&__tag-link-block {
		
	}
	&__tag-link {
		line-height: 17px;
		font-size: 14px;
		color: #9fa9bc;
	}


	// Просмотр новости
	&__link-back {
		border-bottom: none;
	}
	&__no-underscore {
		text-decoration: none;
		&:hover, &:focus {
			text-decoration: none;
		}
	}


}