$layouts-indent: 12px;

.layouts {
	display: flex;
	justify-content: space-between;
	margin-bottom: $layouts-indent;

	// Трехколоночный макет
	&__column-1-of-3,
	&__column-2-of-3,
	&__column-3-of-3 {
		width: 33.33333333%;
	}

	&__indent {
		margin-left: $layouts-indent;
	}

	@media (max-width: 991px) {
		@at-root {
			& {
				display: block;
			}
			&__indent {
				margin-left: 0;
				margin-top: $layouts-indent;
			}
			&__column-1-of-3,
			&__column-2-of-3,
			&__column-3-of-3 {
				width: 100% !important;
			}
		}
	}


	// Двухколоночный макет
	&__column-1-of-2,
	&__column-2-of-2 {
		width: 50%;
		min-width: 40%;
	}

	@media (max-width: 991px) {
		@at-root {
			&__column-1-of-2,
			&__column-2-of-2 {
				width: 100% !important;
				min-width: auto;
			}
		}
	}
}