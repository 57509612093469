@import "../../sprite/_sprite.scss";

.news {

	/*&__tooltip-close-icon {
		display: inline-block;
		@include sprite($news-icon);
	}*/


	// Список новостей
	&__list-item {
		margin-bottom: 30px;
	}

	&__date-block {
		margin-bottom: 4px;
	}
	&__date {
		line-height: 17px;
		font-size: 14px;
		color: #7F7F7F;
	}

	&__header-link-block {
		margin-bottom: 6px;
	}
	&__header-link {
		line-height: 22px;
		font-size: 18px;
		font-weight: bold;
		color: black;
		text-decoration: none;
		&:hover, &:focus {
			color: black;
			text-decoration: underline;
		}
	}

	&__tag-link-block {
		
	}
	&__tag-link {
		line-height: 17px;
		font-size: 14px;
		color: #9fa9bc;
	}


	// Тэги в списке новостей
	&__tag-list-border {
		border-bottom: 1px solid #e9e9e9;
		box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
		margin: 0 -40px 30px -40px;
		padding: 0 40px 0px 40px;
	}
	&__tag-list-wrapper {
		height: 70px;
		overflow-y: hidden;
		overflow-x: visible;
	}
	&__tag-list-block {
		padding-top: 20px;
		padding-right: 95px;
		position: relative;
		display: inline-block;
		font-size: 0;
		//height: 70px;
		margin-bottom: -7px;
		//overflow: hidden;
	}
	&__tag-list {
		//display: flex;
		//flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&__tag-list-item {
		padding-right: 10px;
		padding-bottom: 20px;
		display: block;
		float: left;
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
	&__tag-list-link {
		padding: 6px 14px 7px 14px;
		border-radius: 15px;
		background-color: rgba(206, 219, 242, 0.4);
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		display: block;
		white-space: nowrap;
		cursor: pointer;
		color: black;
		text-decoration: none;
		&:hover, &:focus {
			color: black;
			text-decoration: underline;
		}
		&:after {
			content: "";
			display: block;
			clear: both;
		}
		// Убираю пунктирную рамку в ссылках
		&::-moz-focus-inner {
			border: 0;
		}
		&:hover, &:focus {
			outline: 0;
		}
	}
	&__tag-list-link-label {
		display: block;
		float: left;
	}
	&__tag-list-link-current {
		background-color: #1a48bd;
		color: white;
		&:hover, &:focus {
			color: white;
		}
	}
	&__tag-list-reset {
		float: left;
		display: block;
		margin-left: 7px;
		margin-top: 1px;
		margin-right: -5px;
		padding: 0.5px 3.2px;
		border-radius: 50%;
		line-height: 14px;
		background-color: rgba(255, 255, 255, 0.6);
		color: #1a48bd;
	}
	&__tag-list-label {
		line-height: 17px;
		font-size: 14px;
		color: #7F7F7F;
		padding-top: 6px;
		padding-bottom: 7px;
	}
	&__header-top {
		line-height: 40px;
		font-size: 34px;
		font-weight: bold;
		color: black;
	}

	&__tag-show-all {
		position: absolute;
		top: 23px;
		right: 0;
		padding: 4px 0 7px 0;
		margin-right: 0;
		display: none;
	}
	&__tag-hide-all {
		padding: 7px 10px 7px 0;
		margin-right: 0;
		display: none;
	}


	// Просмотр новости
	&__link-back {
		border-bottom: none;
	}
	&__no-underscore {
		text-decoration: none;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	&__header-detail {
		line-height: 28px;
		font-size: 26px;
		font-weight: bold;
		color: black;
		margin: 30px 0 20px 0;
	}
	&__text {
		font-size: 16px;
		line-height: 20px;
		color: black;
		p {
			margin: 0 0 20px 0;
		}
	}
	&__detail-tag-block {
		padding-top: 26px;
		padding-bottom: 37px;
	}
	&__detail-tag-label {
		line-height: 17px;
		font-size: 14px;
		color: #7F7F7F;
	}
	&__detail-tag {
		line-height: 17px;
		font-size: 14px;
		color: #9fa9bc;
	}
	&__detail-date {
		line-height: 17px;
		font-size: 14px;
		color: #7F7F7F;
		margin-bottom: 10px;
	}


	// Пейджер
	&__pagination-block {
		padding-top: 20px;
	}
	&__pagination {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
	}
	/*&__pagination li:first-child &__pagination-link {
		padding-left: 0;
	}
	&__pagination li:last-child &__pagination-link {
		padding-right: 0;
	}*/
	&__pagination-link {
		line-height: 17px;
		font-size: 14px;
		font-weight: 600;
		color: #003269;
		padding: 8px 14px;
		display: flex;
		align-items: center;
		margin-right: 10px;
		&:hover, &:focus {
			text-decoration: none;
			color: #003269;
		}
	}
	&__pagination-link-current {
		border: 1px solid #cedbf2;
		border-radius: 5px;
	}
	&__pagination-prev-icon {
		display: inline-block;
		@include sprite($news-arrow-left);
		margin-right: 8px;
	}
	&__pagination-next-icon {
		display: inline-block;
		@include sprite($news-arrow-right);
		margin-left: 8px;
	}
	&__pagination-arrow-current {
		opacity: 0.5;
	}
	&__pagination-arrow-block {
		border: 1px solid #cedbf2;
		border-radius: 5px;
	}


	@media (max-width: 767px) {
		@at-root {
			&__pagination-arrow-text {
				display: none;
			}
			&__pagination-arrow-left-block {
				height: 35px;
				padding-right: 5px;
				padding-left: 12px;
			}
			&__pagination-arrow-right-block {
				height: 35px;
				padding-right: 12px;
				padding-left: 5px;
			}
			&__tag-list-block {
				padding-right: 0;
			}
		}
	}
}